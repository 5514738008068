<template>
    <div id="work-view-wrapper">
        <SidebarComp></SidebarComp>
        <v-navigation-drawer app right width="350px" color="white" v-model="right_drawer">
            <div id="diagram-source" v-if="!figma_importer">
                <v-list
                    dense
                    class="px-2"
                    >
                    <v-list-item class="my-4">
                        <h3> Choose a diagram source</h3>
                    </v-list-item>
                </v-list>   
                <v-card
                    width="125px"
                    height="125px"
                    class="text-center d-flex flex-column justify-center align-center ml-6"
                    @click="figma_importer = true"
                    outlined
                >
                    <v-img
                    src="../assets/figma-logo.png"
                    width="75px"
                    height="75px"
                    class="mx-auto mb-n2"
                    contain
                    />
                    <span class="mb-4">FigJam</span>
                </v-card>
            </div>
            <div id="figma-diagram" v-if="figma_importer">
                <v-list
                    dense
                    class="px-2"
                    >
                    <v-list-item class="my-4">
                        <h3> Import from Figma </h3>
                    </v-list-item>
                </v-list>
                <v-form ref="form">
                    <v-list class="ml-2">
                        <v-list-item class="mt-2">
                            <v-list-item-content>
                                <v-list-item-title>1. Diagram Name</v-list-item-title>
                                <v-text-field class="work-textarea mt-4" outlined dense counter maxlength="50" v-model="diagram_name" :rules="[rules.required]"> </v-text-field>
                            </v-list-item-content>
                        </v-list-item> 
                        <v-list-item class="mt-2">
                            <v-list-item-content>
                                <v-btn block outlined :disabled="hasSecretKey" :loading="loading_generate_key" rounded @click="getSecretKey();"> Get Secret Key </v-btn>
                            </v-list-item-content>
                        </v-list-item>
                        <div v-if="hasSecretKey">
                            <v-list-item class="mt-2">
                                <v-list-item-content>
                                    <v-list-item-title>2. Secret Key</v-list-item-title>
                                    <v-list-item-subtitle class="wrap-text">Copy this key and paste it at the Figma plugin. </v-list-item-subtitle>
                                    <v-textarea rows="3" class="work-textarea mt-4" outlined dense v-model="secret_key"> </v-textarea>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-2">
                                <v-list-item-content>
                                    <v-list-item-title>3. Figma Diagram</v-list-item-title>
                                    <v-list-item-subtitle class="wrap-text">After clicking Export at the Figma plugin and getting a success message, check your created artifacts and see your newly created diagram. </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-2">
                                <v-list-item-content>
                                    <v-btn block outlined rounded @click="$router.push('/artifacts')"> See Diagram </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-form>
            </div>
        </v-navigation-drawer>
        <div id="snackbar-container">
            <!-- ERROR AND INFORMATION SNACKBARS -->
            <v-snackbar
                v-model="snackbar_error"
                :color="'error'"
                :timeout="5000"
                :top="true"
                >
                {{ snackbar_error_text }}
            </v-snackbar>
            <v-snackbar
                v-model="snackbar_usage"
                :color="'orange'"
                :timeout="30000"
                :top="true"
                >
                As a free user you can import {{diagram_usage_left}} more diagrams.
            </v-snackbar>
            <!--------------------------------->
        </div>
        <v-main>
            <v-overlay :value="main_loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
            <v-btn absolute top right icon large @click="right_drawer = !right_drawer">
                <v-icon v-if="!right_drawer">mdi-arrow-left-thick</v-icon>
                <v-icon v-else>mdi-arrow-right-thick</v-icon>
            </v-btn>
            <div class="row-container">
                <v-row class="custom-row justify-center align-center">
                    <v-col cols="12">
                        <div class="col-container">
                            <div id="copilot-card" v-if="!hasFirstDraft">
                                <v-card flat class="copilot-card text-center" width="500px">
                                    <div style="position: relative; padding-bottom: 56.25%; height: 0;">
                                        <iframe src="https://www.loom.com/embed/a3cf8a1d8c25472f9ff462413834aebe?sid=db01a005-1eae-4515-8b5c-d8512cc432ea" 
                                                frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                                                @load="onVideoLoaded">
                                        </iframe>
                                        <v-progress-circular v-if="isVideoLoading" 
                                                            indeterminate 
                                                            style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                                            size="64">
                                        </v-progress-circular>
                                    </div>
                                    <v-card-text> Above, what you need to do in Figma. </v-card-text>
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-main>
    </div>
</template>

<script>
import SidebarComp from "@/components/SidebarComp.vue";
import {copyCardContent} from "@/utils/utils.js";
import {getUserStatus, getDiagramFreeUsageLeft} from "@/services/users.js";
import {getProduct} from "@/services/products.js";

const SUBSCRIBER_USER_ROLE_NAME = 'active';
const FREE_USER_ROLE_NAME = 'free';

export default {
    name: 'DiagramView',

    props: ['product_id'],

    components: {
        SidebarComp
    },

    data: () => ({

        main_loading: false,

        user_status: FREE_USER_ROLE_NAME,

        product_name: '',
        product_description: '',

        loading_btn: false,
        isVideoLoading: true,

        diagram_name: '',
        figma_importer: false,

        rules: {
            required: value => !!value || 'Required',
            number: v => (!v || (v >= 1 && v <= 10)) || 'Invalid',
        },

        //Snackbars
        snackbar_error: false,
        snackbar_error_text: '',
        snackbar_usage: false,
        diagram_usage_left: 0,

        loading_save: false,
        right_drawer: false,

        user_leaving_dialog: false,
        can_leave: false,
        next_route: '',

        hasSecretKey: false,
        loading_generate_key: false,
        secret_key: ''


    }),

    created(){
        this.getProduct();
        this.getUserStatus();
        this.navDrawerConfig();
    },

    methods: {

        //UTILS
        copyArtifactContent(element_id){
            copyCardContent(element_id);
        },
        navDrawerConfig(){
            this.right_drawer = window.innerWidth > 800;
        },
        onVideoLoaded() {
            this.isVideoLoading = false;
        },

        //USER Functions
        async getUserStatus(){
            try{
                this.user_status = await getUserStatus();
            }
            catch(e){
                console.log(e);
            }
            
        },

        //PRODUCT Functions
        async getProduct(){
            let product_data;
            try{
                this.main_loading = true;
                product_data = await getProduct(this.product_id);
                this.product_name = product_data.product_name;
                this.product_description = product_data.product_description;
                this.main_loading = false;
            }
            catch(e){
                console.log(e);
                this.snackbar_error = true;
                this.snackbar_error_text = 'Error getting product data.';
            }

        },

        async getSecretKey(){
            if (this.$refs.form.validate()) {
                const generate_key_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/diagram/figma/generate-token';
                this.loading_generate_key = true;
                try{
                    const response = await fetch(generate_key_url, {
                            method: 'POST',
                            headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                            },
                            body: JSON.stringify({
                                "product_id": this.product_id,
                                "artifact_name": this.diagram_name
                            })
                        });

                    const content = await response.json();

                    if(response.status == 200){
                        this.hasSecretKey = true;
                        this.secret_key = content.token;
                        if(this.user_status === FREE_USER_ROLE_NAME){
                            this.diagram_usage_left = await getDiagramFreeUsageLeft();
                            this.snackbar_usage = true;
                        }
                    }
                    //TODO: How to deal better with this
                    else if(response.status == 401 || response.status == 403){
                        this.$router.push('/login');
                    }
                    else if(response.status == 500){
                        console.log(content);
                        this.snackbar_error = true;
                        this.snackbar_error_text = content.message;
                    }

                    else if(response.status == 429){
                        if(this.user_status === SUBSCRIBER_USER_ROLE_NAME){alert('You are making too many requests. Try again later.');}
                        else{alert('You have used all the free content. Please subscribe for more.'); this.$router.push('/pricing');}
                    }

                }
                catch(e){
                    console.log(e);
                }

                this.loading_generate_key = false;
            }
            else{
                this.snackbar_error = true;
                this.snackbar_error_text = "All fields are required";
            }
        },

        
        
    }
};

</script>

<style>

.flex-container {
  display: flex;
}

.col-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.row-container {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.custom-row{
  overflow-y: auto;
  overflow-x: hidden;
}

.copilot-card{
    border-radius: 16px !important;
}

.work-textarea{
    border-radius: 16px !important;
}

.text-linebreak{
    white-space: pre-line;
}

.number-box{
    display: inline-block;
}

.gradient-icon{
   background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.save-container{
    display: flex;
    align-items: center;
}

.wrap-text {
  white-space: normal; /* Allow text to wrap to next line */
  word-wrap: break-word; /* Break words if necessary */
}



</style>