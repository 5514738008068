<template>
    <v-stepper
        v-model="stepper"
        class="stepper-product"
        vertical
        :key="!!questions.length"
    >
        <v-btn absolute top right icon small @click="closeDialog()" style="z-index: 2;">
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <div v-if="questions.length">
            <div v-for="(q, i) in questions" :key="i">
                <v-stepper-step
                    color="black"
                    :complete="stepper > i+1"
                    :step="i+1"
                    complete-icon="mdi-check"
                >
                {{q.question}}
                </v-stepper-step>

                <v-stepper-content :step="i+1">
                    <v-form ref="questions_form" @submit.prevent="goNext();">
                        
                        <v-text-field class="work-textarea mt-4" 
                                        outlined dense counter
                                        :maxlength="getMaxLength(i+1)" 
                                        v-model="questions[i].answer" 
                                        :rules="[rules.required]">   
                        </v-text-field>
                            <v-btn
                                class="mr-4"
                                color="gray"
                                @click="goBack()"
                            >
                                Back
                            </v-btn>
                            
                            <v-btn
                                v-if="i+1 < questions.length"
                                color="black"
                                @click="goNext();"
                            >
                                <span style="color: white;">
                                    Continue
                                </span>
                            </v-btn>

                            <v-btn
                                v-if="i+1 == questions.length"
                                color="primary"
                                :loading="generate_loading"
                                @click="generateDescription();"
                            >
                                Generate
                            </v-btn>
                    </v-form> 
                </v-stepper-content>
            </div>
        </div>
        <v-stepper-step
            color="black"
            :complete="stepper > 8"
            step="8"
            complete-icon="mdi-check"
        >
        ✨ Product Description
        </v-stepper-step>
        <v-stepper-content step="8">
            <v-form ref="description_form" @submit.prevent="submit();">
                Your generated Product Description, modify at will.
                <v-textarea class="work-textarea mt-4" 
                                outlined dense counter auto-grow
                                :maxlength="getMaxLength(8)" 
                                v-model="product_description" 
                                :rules="[rules.required]">   
                </v-textarea>
                    <v-btn
                        class="mr-4"
                        color="gray"
                        @click="closeAndReset()"
                    >
                        Discard
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="submit();"
                    >
                        Save
                    </v-btn>
            </v-form> 
        </v-stepper-content>
    </v-stepper>
</template>

<script>
export default {
  name: 'CreateProductStepper',

  data: () => ({
    stepper: 1,
    total_questions: 7,
    rules: {
        required: value => !!value || 'Required',
    },
    product_name: '',
    product_description: '',
    btn_loading: false,
    generate_loading: false,
    disable_submit: false,
    questions: []
  }),

  mounted() {
    this.getProductDescriptionQuestions();
  },

  methods:{

    closeDialog(){
        this.$emit('close-dialog');
    },

    prodNameNext(){
        if(this.$refs.name_form.validate()){
            this.stepper = 2; 
        }
    },

    getMaxLength(n){
        if(n==1){return 50;}
        else if(n==8){return 1000;}
        else{return 200;}
    },

    mergeArrays(array1, array2){
        if(array1.length === array2.length){
            let mergedArray = array1.map((item, index) => {
            return {...item, ...array2[index]};
            });
        console.log(mergedArray);
        return mergedArray;
        } else {
            throw Error("Arrays are of different lengths and cannot be merged");
        }
    },

    goBack(){
        this.stepper = (this.stepper > 1) ? this.stepper - 1 : this.stepper;
    },

    goNext(){
        this.stepper++;
    },

    closeAndReset(){
        this.product_name = '',
        this.product_description = '',
        this.stepper = 1,
        this.disable_submit = true;
        this.closeDialog();
    },

    async getProductDescriptionQuestions(){
        const prod_descr_quest_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products/description/questions';
        try{
            const response = await fetch(prod_descr_quest_url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                },
            });

            const content = await response.json();

            if(response.status == 200){
                this.questions = content.product_description_questions;
            }
            if(response.status == 401 || response.status == 403){
                this.$router.push('/login');
            }
            if(response.status == 500){
                console.log(content.message);
            }
        }
        catch(e){
            console.log(e);
        }
    },

    async generateDescription(){
        this.generate_loading = true;
        try{
            this.$refs.questions_form.forEach(form => {
                if(!form.validate()){
                    throw Error("Please fill out the missing questions.");
                }
            });
            const prod_descr_quest_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products/description/questions';
            const response = await fetch(prod_descr_quest_url, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                },
                body: JSON.stringify({
                    "qa_list": this.questions
                })
            });

            const content = await response.json();

            if(!content.product_description){throw Error("Response empty");}

            if(response.status == 200){
                this.product_description = content.product_description;
                this.stepper++;
            }
            if(response.status == 401 || response.status == 403){
                this.$router.push('/login');
            }
            if(response.status == 429){
                alert("You have reached your new product limits.");
            }
            if(response.status == 500){
                console.log(content.message);
            }
        }
        catch(e){
            alert(e);
            console.log(e);
        }
        finally{
            this.generate_loading = false;
        }
    },

    async submit(){
        if(this.$refs.description_form.validate() && !!this.questions[0].answer){
            this.btn_loading = true;
            this.product_name = this.questions[0].answer;
            const create_product_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products';
            try{
                const response = await fetch(create_product_url, {
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                        },
                        body: JSON.stringify({
                            "product_name": this.product_name,
                            "product_description": this.product_description
                        })
                    });

                const content = await response.json();

                if(response.status == 200){
                    let prod = {
                        product_id: content.product_id, 
                        product_name: this.product_name, 
                        product_description: this.product_description,
                        updated: new Date()
                    };
                    this.$emit('create-product', prod);
                    this.product_name = '',
                    this.product_description = '',
                    this.stepper = 1,
                    this.disable_submit = true;
                    this.closeDialog();
                }
                if(response.status == 401 || response.status == 403){
                    this.$router.push('/login');
                }
                if(response.status == 429){
                    alert("You have reached your new product limits.");
                }
                if(response.status == 500){
                    console.log(content.message);
                }
            }
            catch(e){
                console.log(e);
            }
            finally{
                this.btn_loading = false;
            }
        }

    }
  }
};
</script>

<style scoped>
.stepper-product {
    min-width: 350px !important;
    max-width: 600px !important;
}

.stepper-subtitle{
    font-size: 0.875rem !important;
}


</style>