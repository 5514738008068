<template>
    <div>
        <SidebarComp></SidebarComp>
    <v-main>
        <v-overlay :value="main_loading">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
        <v-container fluid class="breathing-room main-container">
            <v-row class="pt-4">
                <v-col cols = "12 d-flex">
                    <h1 class="ml-2 mt-6 gradient-card-title">Editing: {{product.product_name}}</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12 d-flex" >
                    <v-text-field
                    class="custom-input"
                    label="Product Name"
                    v-model="product.product_name"
                    maxlength="50"
                    counter
                    filled
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12 d-flex">
                    <v-textarea
                    label="Product Description"
                    v-model="product.product_description"
                    multiline
                    filled
                    maxlength="1000"
                    counter
                    auto-grow
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="updateProduct()" :loading="btn_loading">Update</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
    </div>
</template>       

<script>
import SidebarComp from '@/components/SidebarComp.vue';

export default {
    name: 'ProductView',

    components: {
        SidebarComp
    },

    props: ['id'],

    data: () => ({

        product: { },
        main_loading: true,
        btn_loading: false
    }),

    created(){
        this.getProduct();
        this.main_loading = false;
    },

    methods: {
        async getProduct(){
            try{
                const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products/' + this.id;
                const response = await fetch(base_url, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                    }
                });

                // If status success (code 200)
                if(response.status != 200){
                    throw Error('Error getting Products.');
                }
                else{
                    const content = await response.json();
                    this.product = content;
                }
            }
            catch(error){
                alert('Error getting Products');
            }
        },

        async updateProduct(){
            this.main_loading = true;
            this.btn_loading = true;
            try{
                const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products/' + this.id;
                const response = await fetch(base_url, {
                    method: 'PUT',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                    },
                    body: JSON.stringify({
                        "product_id": this.id,
                        "product_name": this.product.product_name,
                        "product_description": this.product.product_description,
                    })
                    
                });

                // If status success (code 200)
                if(response.status != 200){
                    throw Error('Error updating product.');
                }
                else{
                    alert("Product Updated.")
                }
            }
            catch(error){
                alert('Error updating product.');
            }
            this.main_loading = false;
            this.btn_loading = false;
        }
    }
}
</script>

<style scoped>
.gradient-card-title{
    background: linear-gradient(to right, #27374D  0%, #007232 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    }
.v-input__control {
  background: white !important; /* You can change this to your preferred color */
}
.main-container{
    overflow-y: auto !important;
    height: 100vh;
}
/* Default (XS) */
.breathing-room {
padding-left: 5% !important;
padding-right: 5% !important;
}

/* SM and up */
@media (min-width: 600px) {
.breathing-room {
    padding-left: 7% !important;
    padding-right: 7% !important;
}
}

/* MD and up */
@media (min-width: 960px) {
.breathing-room {
    padding-left: 10% !important;
    padding-right: 10% !important;
}
}

/* LG and up */
@media (min-width: 1264px) {
.breathing-room {
    padding-left: 20% !important;
    padding-right: 20% !important;
}
}

/* XL and up */
@media (min-width: 1904px) {
.breathing-room {
    padding-left: 25% !important;
    padding-right: 25% !important;
}
}
</style>