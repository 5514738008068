<template>
<div id="work-view-wrapper">
    <SidebarComp></SidebarComp>
    <v-dialog v-model="show_prd_save_name" max-width="400px">
        <v-card class="pa-6">
            <v-form ref="save_prd_name" @submit.prevent="savePRD()">
                <v-text-field class="work-textarea" outlined dense counter maxlength="50" v-model="prd_name" label="PRD Custom Name" :rules="[rules.required]"> </v-text-field> 
                <v-btn color="primary" @click="savePRD(); right_drawer = false;" :loading="loading_save" :disabled="prd_saved"> Save </v-btn>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="user_leaving_dialog" persistent max-width="400px">
        <v-card>
            <v-card-text class="pt-6">You haven't saved this artifact. Are you sure you want to leave?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined text @click="confirmLeave()">Yes, discard this.</v-btn>
                <v-btn text @click="user_leaving_dialog = false">No, let me save it.</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-navigation-drawer app right width="350px" color="white" v-model="right_drawer">
        <v-form ref="form">
        <v-list
            dense
            class="px-2"
            >
                <v-list-item class="my-4">
                    <h3> Generate a first draft for <br/> {{product_name}}</h3>
                </v-list-item>
                <v-list-item class="my-2">
                    <v-list-item-content>
                        <v-list-item-title>Functional Requirements</v-list-item-title>
                        <v-list-item-subtitle > Generate <v-text-field class="number-box mx-2" type="number" v-model="fr_number" style="width: 40px" :rules="[rules.required, rules.number]"></v-text-field> requirements </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item class="my-2">
                    <v-list-item-content>
                        <v-list-item-title>Non-Functional Requirements</v-list-item-title>
                        <v-list-item-subtitle> Generate <v-text-field class="number-box mx-2" type="number" v-model="nfr_number" style="width: 40px" :rules="[rules.required, rules.number]"></v-text-field> requirements </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-hover v-slot="{hover}">
                        <v-btn block outlined class="mt-2 mb-8" :loading="loading_btn" rounded @click="generateDraft()"><v-icon left :class="{'gradient-icon': hover}"> fi-sr-rocket-lunch </v-icon> Go! </v-btn>
                    </v-hover>
                </v-list-item>
            </v-list>     
        </v-form>
    </v-navigation-drawer>

    <!-- ERROR AND INFORMATION SNACKBARS -->
    <v-snackbar
        v-model="snackbar_error"
        :color="'error'"
        :timeout="5000"
        :top="true"
        >
        {{ snackbar_error_text}}
    </v-snackbar>
    <v-snackbar
        v-model="snackbar_usage"
        :color="'orange'"
        :timeout="30000"
        :top="true"
        >
        As a free user you can generate {{generate_usage_left}} more artifacts.
    </v-snackbar>
    <!--------------------------------->

    <v-main>
        <v-overlay :value="main_loading">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
        <v-btn absolute top right icon large @click="right_drawer = !right_drawer">
            <v-icon v-if="!right_drawer">mdi-arrow-left-thick</v-icon>
            <v-icon v-else>mdi-arrow-right-thick</v-icon>
        </v-btn>
        <div class="row-container">
            <v-row class="custom-row justify-center align-center">
                <v-col cols="12">
                    <div class="col-container">
                        <div id="copilot-card" v-if="!hasFirstDraft">
                            <v-card flat class="copilot-card text-center">
                                <v-img src="../assets/spaceship-card.png" max-width="350px"/>
                                <v-card-text> Send your copilots on a quest. <br> They always come back with an answer. </v-card-text>
                            </v-card>
                        </div>
                        <div id="document" v-if="hasFirstDraft" class="mb-6">
                            <v-card id="prd-draft-card" max-width="600px" class="pa-2 mt-6 text-linebreak rounded-xl">
                                <v-card-title> {{'Product Overview' + '\n'}} <v-spacer></v-spacer> <v-icon class="mr-2" color="gray" @click="preSavePRD()" :disabled="show_prd_save_name"> mdi-content-save </v-icon> <v-icon color="gray" :style="{'transform': 'rotate(90deg)'}" @click="copyArtifactContent('prd-draft-card')"> mdi-card-multiple </v-icon> </v-card-title>
                                <v-card-text> {{document['Product Overview'] + '\n'}} </v-card-text>
                                <v-card-title> {{'Functional Requirements' + '\n'}} </v-card-title>
                                <div v-for="(fr, i) in document['Functional Requirements']" :key="'fr-' + i">
                                    <v-card-subtitle>{{'FR' + String(i + 1) + ' - ' + fr.title + '\n'}}</v-card-subtitle>
                                    <v-card-text class="text--secondary">{{fr.description + '\n'}}</v-card-text>
                                </div>
                                <v-card-title> {{'Non-Functional Requirements' + '\n'}} </v-card-title>
                                <div v-for="(fr, i) in document['Non-Functional Requirements']" :key="'nfr-' + i">
                                    <v-card-subtitle>{{'NFR' + String(i + 1) + ' - ' + fr.title + '\n'}}</v-card-subtitle>
                                    <v-card-text class="text--secondary">{{fr.description + '\n'}}</v-card-text>
                                </div>
                            </v-card>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-main>
</div>
</template>

<script>
import SidebarComp from "@/components/SidebarComp.vue";
import {copyCardContent} from "@/utils/utils.js";
import {getUserStatus, getGenerateFreeUsageLeft} from "@/services/users.js";
import {getProduct} from "@/services/products.js";

const SUBSCRIBER_USER_ROLE_NAME = 'active';
const FREE_USER_ROLE_NAME = 'free';

export default {
    name: 'PRDWriterView',

    props: ['product_id'],

    components: {
        SidebarComp
    },

    data: () => ({

        //Main App Loading
        main_loading: false,

        user_status: FREE_USER_ROLE_NAME,

        //Document parameters
        fr_number: 5,
        nfr_number: 2,
        hasFirstDraft: false,

        product_name: '',
        product_description: '',

        loading_btn: false,

        document: {},

        rules: {
            required: value => !!value || 'Required',
            number: v => (!v || (v >= 1 && v <= 10)) || 'Invalid',
        },

        snackbar_error: false,
        snackbar_error_text: '',
        prd_name: '',
        prd_saved: false,
        loading_save: false,
        show_prd_save_name: false,
        right_drawer: false,

        user_leaving_dialog: false,
        can_leave: false,
        next_route: '',

        snackbar_usage: false,
        generate_usage_left: 0


    }),

    beforeRouteLeave(to, from, next) {
        if (this.prd_saved || this.can_leave || (!this.prd_saved && !this.hasFirstDraft)) {
            next();
        } else {
            this.user_leaving_dialog = true;
            this.nextRoute = to.path; 
        }
    },

    created(){
        this.getProduct();
        this.getUserStatus();
        this.navDrawerConfig();
    },

    methods: {

        //UTILS
        copyArtifactContent(element_id){
            copyCardContent(element_id);
        },
        navDrawerConfig(){
            this.right_drawer = window.innerWidth > 800;
        },

        //USER Functions
        async getUserStatus(){
            try{
                this.user_status = await getUserStatus();
            }
            catch(e){
                console.log(e);
            }
            
        },

        //PRODUCT Functions
        async getProduct(){
            let product_data;
            try{
                this.main_loading = true;
                product_data = await getProduct(this.product_id);
                this.product_name = product_data.product_name;
                this.product_description = product_data.product_description;
                this.main_loading = false;
            }
            catch(e){
                console.log(e);
                this.snackbar_error = true;
                this.snackbar_error_text = 'Error getting product data.';
            }

        },
        
        //FORM Validation
        validateFormNumbers() {
            const numbers = ['fr_number', 'nfr_number'];
            for (const num of numbers) {
            if (!this[num] || this[num] < 1 || this[num] > 10) {
                return { valid: false, message: 'Number must be between 1 and 10' };
            }
            }
            return { valid: true };
        },

        //ARTIFACT Functions
        async generateDraft(){
            const numberCheck = this.validateFormNumbers();
            if (numberCheck.valid) {
                this.document = {};
                const prd_draft_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/prd/generate';
                this.loading_btn = true;
                this.main_loading = true;
                try{
                    const response = await fetch(prd_draft_url, {
                            method: 'POST',
                            headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                            },
                            body: JSON.stringify({
                                "product_name": this.product_name,
                                "product_description": this.product_description,
                                "fr_number": this.fr_number,
                                "nfr_number": this.nfr_number
                            })
                        });

                    const content = await response.json();

                    if(response.status == 200){
                        this.hasFirstDraft = true;
                        this.right_drawer = false;
                        if(this.user_status === FREE_USER_ROLE_NAME){
                            this.generate_usage_left = await getGenerateFreeUsageLeft();
                            this.snackbar_usage = true;
                        }
                    }
                    //TODO: How to deal better with this
                    else if(response.status == 401 || response.status == 403){
                        this.$router.push('/login');
                    }
                    else if(response.status == 500){
                        console.log(content);
                        this.snackbar_error = true;
                        this.snackbar_error_text = content.message;
                    }

                    else if(response.status == 429){
                        if(this.user_status === SUBSCRIBER_USER_ROLE_NAME){alert('You are making too many requests. Try again later.');}
                        else{alert('You have used all the free content. Please subscribe for more.'); this.$router.push('/pricing');}
                    }
                    else{
                        return
                    }
                    
                    this.document = content;
                }
                catch(e){
                    console.log(e);
                }

                this.loading_btn = false;
                this.main_loading = false;
            }
            else{
                this.snackbar_error = true;
                this.snackbar_error_text = "All fields are required";
            }
        },

        preSavePRD(){
            this.show_prd_save_name = true;
        },

        async savePRD(){
            if(this.$refs.save_prd_name.validate()){
                this.loading_save = true;
                this.show_prd_save_name = true;

                const prd_save_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/prd/';
                try{
                    const response = await fetch(prd_save_url, {
                            method: 'POST',
                            headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                            },
                            body: JSON.stringify(
                                {
                                    "product": {"product_id": this.product_id},
                                    "artifact": {
                                        "artifact_name": this.prd_name,
                                        "artifact_data": this.document
                                    }
                                })
                    });

                    const content = await response.json();

                    if(response.status == 200){
                        this.prd_saved = true;
                        alert("Artifact Saved!");
                    }
                    //TODO: How to deal better with this
                    else if(response.status == 401 || response.status == 403){
                        this.$router.push('/login');
                    }
                    else if(response.status == 500){
                        console.log(content.message);
                        alert("Something wrong with the request.");
                        //push to 500 page
                    }
                    else{
                        alert("Something wrong with the request.");
                    }
                }
                catch(e){
                    alert("Something wrong with the request.");
                }
                finally{
                    this.loading_save = false;
                    this.show_prd_save_name = false;
                }
            }
        },

        confirmLeave() {
            this.can_leave = true;
            this.user_leaving_dialog = false;
            this.$router.push(this.nextRoute); // navigate to the next route
        },

        
        
    }
};

</script>

<style>

.flex-container {
  display: flex;
}

.col-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.row-container {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.custom-row{
  overflow-y: auto;
  overflow-x: hidden;
}

.copilot-card{
    border-radius: 16px !important;
}

.work-textarea{
    border-radius: 16px !important;
}

.text-linebreak{
    white-space: pre-line;
}

.number-box{
    display: inline-block;
}

.gradient-icon{
   background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.save-container{
    display: flex;
    align-items: center;
}

</style>