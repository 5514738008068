export function copyCardContent(element_id) {
    // Get the v-card element
    const card = document.querySelector('#' + element_id);

    // Get the text content of the v-card
    const content = card.textContent;

    // Use the Clipboard API to write the text content to the clipboard
    navigator.clipboard.writeText(content)
        .then(() => {
        alert("Text Copied to the Clipboard.");
        })
        .catch((error) => {
        console.error('Failed to copy text: ', error);
        });
}