<template>
    <div>
        <SidebarComp></SidebarComp>
        <div id="snackbar-container">
            <!-- ERROR AND INFORMATION SNACKBARS -->
            <v-snackbar
                v-model="snackbar_error"
                :color="'error'"
                :timeout="5000"
                :top="true"
                >
                {{ snackbar_error_text }}
            </v-snackbar>
            <!--------------------------------->
        </div>
        <!-- Artifact View Dialogs -->
        <v-dialog v-model="dialog" max-width="600px">
            <PRDViewerDialog v-if="show_prd" :document="show_document" :artifact_id="show_artifact_id" @close-dialog="closeArtifact()" @artifact-deleted="handleArtifactDeletion"> </PRDViewerDialog>
            <EpicViewerDialog v-if="show_epic" :document="show_document" :artifact_id="show_artifact_id" @close-dialog="closeArtifact()" @artifact-deleted="handleArtifactDeletion"> </EpicViewerDialog>
            <ProdInitViewerDialog v-if="show_prod_init" :document="show_document" :artifact_id="show_artifact_id" @close-dialog="closeArtifact()" @artifact-deleted="handleArtifactDeletion"> </ProdInitViewerDialog>
            <DiagramViewerDialog v-if="show_diagram" :document="show_document" :image="show_image" :product_id="id" :artifact_id="show_artifact_id" @close-dialog="closeArtifact()" @artifact-deleted="handleArtifactDeletion"> </DiagramViewerDialog>
            <CustomTextViewerDialog v-if="show_custom_text" :document="show_document" :artifact_id="show_artifact_id" @close-dialog="closeArtifact()" @artifact-deleted="handleArtifactDeletion"> </CustomTextViewerDialog>
        </v-dialog >

        <!-- New Artifact Dialog -->
        <NewArtifactDialog v-model="new_artifact_dialog" @selected-new-artifact="handleSelectedArtifact" @close-dialog="closeNewArtifact()"> </NewArtifactDialog>

        <!-- Artifact type selected, going through the setup -->
        <!-- TODO in the future
        <NewPRDWriterDialog v-model="new_prd_writer_dialog" :product_id="id" @complete-new-artifact="handleCompleteNewArtifact" @close-dialog="closeArtifactCreation()"> </NewPRDWriterDialog>
        
        <NewEpicWriterDialog v-model="new_epic_writer_dialog" @complete-new-artifact="handleCompleteNewArtifact"> </NewEpicWriterDialog>
        
        <NewProdInitDialog v-model="new_prod_init_dialog" @complete-new-artifact="handleCompleteNewArtifact"> </NewProdInitDialog>
        <NewImportDiagramDialog v-model="new_import_diagram_dialog" @complete-new-artifact="handleCompleteNewArtifact"> </NewImportDiagramDialog>
        -->

        <!-- Delete prompt dialog -->
        <div id="delete-dialog">
            <v-dialog v-model="delete_dialog" persistent max-width="300px">
                <v-card class="pb-2">
                    <v-card-title>Confirmation</v-card-title>
                    <v-card-text>Are you sure you want to delete this product?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="deleteProduct()">Yes</v-btn>
                        <v-btn color="primary" text @click="delete_dialog = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <v-main>
            <v-overlay :value="main_loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
                <v-container fluid class="pt-12 pl-12 pr-12 v-scroll-y main-container breathing-room">
                    
                    <div id="product-header">
                        <v-row>
                            <v-col cols = "12 d-flex">
                                <!--<h1 class="mt-2 gradient-card-title"> {{product.product_name}}</h1>-->
                                <h1 class="mt-2"> {{product.product_name}}</h1> 
                            </v-col>
                        </v-row>
                        <v-row id="product-actions" class="mt-n6">
                            <v-col cols = "12 d-flex">
                                <router-link :to="'/settings/product/' + id">
                                    <span class="text--secondary"> Edit </span>
                                </router-link>
                                <!--
                                <span class="text--secondary ml-4 link-style" @click="deleteProductDialog()"> Delete </span>
                                -->
                            </v-col>
                        </v-row>
                    </div>
                    <div id="artifact-section">
                        <v-row class = "mt-8">
                            <v-col cols="12 d-flex">
                                <!--<h2 class = "gradient-card-title"> Artifacts </h2> -->
                                <h2> Artifacts </h2> 
                                <v-spacer> </v-spacer> 
                                    <v-chip
                                    color="black"
                                    outlined
                                    @click="new_artifact_dialog = true"
                                    >
                                        <v-icon left>
                                        mdi-plus-circle
                                        </v-icon>
                                        New Artifact
                                    </v-chip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" v-if="artifacts && artifacts.length === 0">
                                <h4 class="text--secondary"> You haven't created artifacts for this product 🧐 </h4>
                            </v-col>

                            <v-col cols="12">
                                <v-data-table 
                                    hide-default-header
                                    :headers="headers"
                                    :items="artifacts"
                                    @click:row="showArtifact">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
        </v-main>
    </div>
</template>

<script>
import SidebarComp from '@/components/SidebarComp.vue';

import EpicViewerDialog from '@/components/EpicViewerDialog.vue';
import PRDViewerDialog from '@/components/PRDViewerDialog.vue';
import ProdInitViewerDialog from '@/components/ProdInitViewerDialog.vue';
import DiagramViewerDialog from '@/components/DiagramViewerDialog.vue';
import CustomTextViewerDialog from '@/components/CustomTextViewerDialog.vue';

import NewArtifactDialog from '@/components/NewArtifactDialog.vue';

//import NewImportDiagramDialog from '@/components/NewArtifact/ArtifactImportDiagramDialog.vue';
//import NewPRDWriterDialog from '@/components/NewArtifact/ArtifactPRDWriterDialog.vue';

import {getProduct, deleteProduct} from "@/services/products.js";
import {getArtifacts} from "@/services/artifacts.js";


export default {
    name: 'ProductView',

    components: {
        SidebarComp,
        EpicViewerDialog,
        PRDViewerDialog,
        ProdInitViewerDialog,
        DiagramViewerDialog,
        NewArtifactDialog,
        CustomTextViewerDialog
    },

    //props: ['id'],

    data: () => ({

        product: {
            product_name: ''
        },

        id: '',

        main_loading: true,

        artifacts: [ ],

        headers: [
            { text: 'Name', value: 'artifact_name' },
            { text: 'Type', value: 'artifact_type' },
            { text: 'Last Updated', value: 'updated' },
            
        ],

        snackbar_error: false,
        snackbar_error_text: '',

        show_document: {},
        show_image: {},
        show_artifact_id: '',
        dialog: false,
        show_prd: false,
        show_epic: false,
        show_prod_init: false,
        show_diagram: false,
        show_custom_text: false,

        new_artifact_dialog: false,
        new_import_diagram_dialog: false,
        new_prd_writer_dialog: false,
        new_epic_writer_dialog: false,
        new_prod_init_dialog: false,

        delete_dialog: false

    }),

    async mounted(){
        let product = await this.getActiveProduct();
        if(product){
            this.id = product.product_id;
            this.getProduct();
            this.getArtifacts();
        }
        else{
            alert('Select or Create a Product first.');
            this.$router.push('/products');
        }
        this.main_loading = false;
        
        
    },
    

    methods: {

        async getActiveProduct() {
            // If it's not set, fetch it
            if (!this.$store.getters.activeProduct) {
                await this.$store.dispatch('fetchActiveProduct');
            }

            return this.$store.getters.activeProduct;
        },
        
        //UTILS
        humanDate(robot_date){
            let date = new Date(robot_date);
            let humanReadableDate = date.toLocaleString();
            return humanReadableDate;
        },

        //PRODUCT Functions
        async getProduct(){
            let product_data;
            try{
                product_data = await getProduct(this.id);
                this.product = product_data;
            }
            catch(e){
                console.log(e);
                this.snackbar_error = true;
                this.snackbar_error_text = 'Error getting product data.';
            }
        },
        deleteProductDialog(){
            this.delete_dialog = true;
        },
        async deleteProduct(){

            try{
                let result = await deleteProduct(this.id);
                if(result){
                    this.delete_dialog = false;
                    this.$router.push('/');
                }
            }
            catch(e){
                console.log(e);
                this.snackbar_error = true;
                this.snackbar_error_text = 'Error getting product data.';
            }

        },
        
        //ARTIFACT Functions
        //this can be modularized
        async getArtifacts(){

            let artifacts;

            try{
                artifacts = await getArtifacts({"product": this.id, "order": "desc"});
                this.artifacts = artifacts.map(item => {
                        item.updated = this.humanDate(item.updated);
                        return item;
                });
            }
            catch(e){
                alert('Error getting Artifacts.');
            }

        },
        showArtifact(item){

            let artifact_data_json;
            this.show_document = {};
            this.show_image = {};
            
            try{
                artifact_data_json = JSON.parse(item.artifact_data);
                this.show_artifact_id = item.artifact_id;
            }
            catch(e){
                alert("Error getting artifact data");
                return;
            }

            this.dialog = true;
            if (artifact_data_json) {
                // Check if the content property exists
                if ('content' in artifact_data_json) {
                    this.show_document = artifact_data_json.content;
                } 
                // Check if the image property exists and has a url
                if (artifact_data_json.image) {
                    this.show_image = artifact_data_json.image;
                } 
            }
            
            if(item.artifact_type === 'PRD'){
                this.show_prd = true;
            }
            else if(item.artifact_type === 'EPIC'){
                this.show_epic = true;
            }
            else if(item.artifact_type === 'PRODUCT INITIATIVE'){
                this.show_prod_init = true;
            }
            else if(item.artifact_type === 'FIGMA-DIAGRAM'){
                this.show_diagram = true;
            }
            else if(item.artifact_type === 'CUSTOM-TEXT'){
                this.show_custom_text = true;
            }
            else{
                this.dialog = false;
            }

        },
        closeArtifact(){
            this.show_prd = false;
            this.show_epic = false;
            this.show_prod_init = false;
            this.show_diagram = false;
            this.dialog = false;
        },
        closeNewArtifact(){
            this.new_artifact_dialog = false;   
        },
        closeArtifactCreation(){
            this.new_prd_writer_dialog = false;
        },
        handleArtifactDeletion(artifact_id){
            this.artifacts = this.artifacts.filter(artifact => artifact.artifact_id !== artifact_id);
        },
        handleSelectedArtifact(artifact_obj){
            this.$router.push(artifact_obj.artifact_url + this.id);
            return;
        },
        

    },

    
}
</script>

<style scoped>
    .main-card {
        border-radius: 16px !important;
        cursor: pointer !important;
    }

    .gradient-icon{
    background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    }

    .inactive-card-title{
        color: gray;
    }

    .active-text{
        color: black !important;
    }

    .gradient-card-title{
    background: linear-gradient(to right, #27374D  0%, rgb(0, 114, 50) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    }

    .hovered{
        border: 2px solid #007232 !important;
    }

    .scrollable-cards {
        overflow-x: auto;
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */
    }

    .scrollable-cards::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }

    .main-container{
        overflow-y: auto !important;
        height: 100vh;
    }

    .link-style {
        text-decoration: underline; /* Add an underline */
        cursor: pointer; /* Change the cursor to a pointer */
    }

    /* Default (XS) */
    .breathing-room {
    padding-left: 5% !important;
    padding-right: 5% !important;
    }

    /* SM and up */
    @media (min-width: 600px) {
    .breathing-room {
        padding-left: 7% !important;
        padding-right: 7% !important;
    }
    }

    /* MD and up */
    @media (min-width: 960px) {
    .breathing-room {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
    }

    /* LG and up */
    @media (min-width: 1264px) {
    .breathing-room {
        padding-left: 20% !important;
        padding-right: 20% !important;
    }
    }

    /* XL and up */
    @media (min-width: 1904px) {
    .breathing-room {
        padding-left: 25% !important;
        padding-right: 25% !important;
    }
    }



</style>