import { getUserAuthToken } from "@/utils/cookies";

const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products';

export async function getProduct(product_id){
    
    const url = base_url + '/' + product_id;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getUserAuthToken()
        }
    });

    // If status success (code 200)
    if(response.status != 200){
        throw Error('Error getting products.');
    }
    else{
        const content = await response.json();
        return content;
    }
    
}

export async function createProduct(product_name, product_description){
  const response = await fetch(base_url, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getUserAuthToken()
          },
          body: JSON.stringify({
              "product_name": product_name,
              "product_description": product_description
          })
      });

  const content = await response.json();

  if(response.status == 401 || response.status == 403){
      throw Error("Unauthorized");
  }
  if(response.status == 500){
      throw Error("Something went wrong")
  }

  return content.product_id;

}

export async function deleteProduct(product_id){

    const url = base_url + '/' + product_id;
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getUserAuthToken()
        }
    });

    if(response.status != 200){
        throw Error('Error deleting products.');
    }
    else if(response.status == 200){
        return true;
    }
}

function getActive(arr) {
    for (let obj of arr) {
      if (obj.active) {
        return obj;
      }
    }
    return null;
  }

export async function getActiveProduct(){
    try{
      const response = await fetch(base_url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getUserAuthToken()
          }
      });
  
      // If status success (code 200)
      if(response.status == 200){
        let products = await response.json();
        let active_product = getActive(products);
        return active_product;
      }
    }
    catch(e){
      return null;
    }
}