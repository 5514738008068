<template>
    <div>
        <v-dialog v-model="delete_dialog" persistent max-width="300px">
            <v-card class="pb-2">
                <v-card-title>Confirmation</v-card-title>
                <v-card-text>Are you sure you want to delete this artifact?</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="deleteArtifact()">Yes</v-btn>
                <v-btn color="primary" text @click="delete_dialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card flat class="copilot-card pa-6 pt-8">
            <div class="mt-8">
                <v-btn absolute top right icon small @click="closeDialog()" style="z-index: 2;">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn absolute top right icon small @click="deleteArtifactDialog()" style="z-index: 2;" class="mr-8">
                    <v-icon color="gray"> mdi-delete </v-icon>
                </v-btn>
            </div>
            <v-divider class="mb-4"></v-divider>
            <v-img class="mb-8" :src="diagram_image.url" max-width="600px"/>
            <v-divider class="mb-4"></v-divider>
            <v-card-title> Quick Artifacts</v-card-title>
            <v-card-text> Generate artifacts from this diagram. </v-card-text>
            <v-card-actions>
                <v-btn
                    outlined
                    rounded
                    text
                    color="primary"
                    :disabled="disable_req"
                    :loading="loading_req"
                    @click="generateRequirements()"
                >
                    Requirements
                </v-btn>
                <v-btn
                    outlined
                    rounded
                    text
                    color="primary"
                    :disabled="disable_us"
                    :loading="loading_us"
                    @click="generateUserStories()"
                >
                    User Stories
                </v-btn>
            </v-card-actions>
            <div id="container_req">
                <div v-if="requirements_show">
                    <v-card-title> Functional Requirements </v-card-title>
                    <div v-for="(req, i) in requirements" :key="i">
                        <v-card-subtitle> {{req.title}} </v-card-subtitle>
                        <v-card-text> {{req.description}} </v-card-text>
                    </div>
                </div>
            </div>

            <div v-if="user_story_show">
                <v-card-title> User Stories </v-card-title>
                <div v-for="(us, i) in user_stories" :key="i">
                    <v-card-subtitle> {{us.title}} </v-card-subtitle>
                    <v-card-text> {{us.description}} </v-card-text>
                </div>
            </div>
        </v-card>
        
    </div>
</template>

<script>
export default {
    name: 'DiagramViewerDialog',

    props: ['document', 'image', 'artifact_id', 'product_id'],

    data: () => ({

        diagram_content: {},
        diagram_image: {},
        delete_dialog: false,

        requirements: {},
        user_stories: {},
        requirements_show: false,
        user_story_show: false,

        loading_req: false,
        loading_us: false,
        disable_req: false,
        disable_us: false

    }),

    created(){
        this.diagram_content = this.document;
        this.diagram_image = this.image;
    },

    methods:{
        closeDialog(){
            this.$emit('close-dialog');
        },
        copyCardContent() {
                // Get the v-card element
                const card = document.querySelector('#prod_init-draft-card');

                // Get the text content of the v-card
                const content = card.textContent;

                // Use the Clipboard API to write the text content to the clipboard
                navigator.clipboard.writeText(content)
                    .then(() => {
                    alert("Text Copied to the Clipboard.");
                    })
                    .catch((error) => {
                    console.error('Failed to copy text: ', error);
                    });
        },

        deleteArtifactDialog(){
            this.delete_dialog = true;
        },

        async deleteArtifact(){
            try{
                const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/' + this.artifact_id;
                const response = await fetch(base_url, {
                    method: 'DELETE',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                    }
                });

                if(response.status != 200){
                    throw Error('Error deleting Artifacts.');
                }
                else if(response.status == 200){
                    this.delete_dialog = false;
                    this.$emit('artifact-deleted', this.artifact_id);
                    this.$emit('close-dialog');
                }
            }
            catch(error){
                alert('Error getting Artifacts.');
            }
        },

        async generateRequirements(){
            //const fieldCheck = this.validateFields();
                this.requirements = {};
                this.requirements_show = false;
                const SUBSCRIBER_USER_ROLE_NAME = 'active';
                const diagram_reqs_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/diagram/figma/quick-generate-reqs';
                this.loading_req = true;
                try{
                    const response = await fetch(diagram_reqs_url, {
                            method: 'POST',
                            headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                            },
                            body: JSON.stringify({
                                "product_id": this.product_id,
                                "diagram_content": this.diagram_content,
                            })
                        });

                    const content = await response.json();

                    if(response.status == 200){
                        this.requirements_show = true;
                        this.requirements = content;
                        const element = document.querySelector('container_req');
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                    //TODO: How to deal better with this
                    else if(response.status == 401 || response.status == 403){
                        this.$router.push('/login');
                    }
                    else if(response.status == 500){
                        console.log(content);
                        return;
                    }

                    else if(response.status == 429){
                        if(content.user_status === SUBSCRIBER_USER_ROLE_NAME){alert('You are making too many requests. Try again later.');}
                        else{alert('You have used all the free content. Please subscribe for more.'); this.$router.push('/pricing');}
                    }
                    else{
                        return
                    }
                }
                catch(e){
                    console.log(e);
                }

                this.loading_req = false;
            },

            async generateUserStories(){
            //const fieldCheck = this.validateFields();
                this.user_stories = {};
                this.user_story_show = false;
                const SUBSCRIBER_USER_ROLE_NAME = 'active';
                const diagram_userstories_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/diagram/figma/quick-generate-user-stories';
                this.loading_us = true;
                try{
                    const response = await fetch(diagram_userstories_url, {
                            method: 'POST',
                            headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                            },
                            body: JSON.stringify({
                                "product_id": this.product_id,
                                "diagram_content": this.diagram_content,
                            })
                        });

                    const content = await response.json();

                    if(response.status == 200){
                        this.user_story_show = true;
                        this.user_stories = content;
                    }
                    //TODO: How to deal better with this
                    else if(response.status == 401 || response.status == 403){
                        this.$router.push('/login');
                    }
                    else if(response.status == 500){
                        console.log(content);
                        return;
                    }

                    else if(response.status == 429){
                        if(content.user_status === SUBSCRIBER_USER_ROLE_NAME){alert('You are making too many requests. Try again later.');}
                        else{alert('You have used all the free content. Please subscribe for more.'); this.$router.push('/pricing');}
                    }
                    else{
                        return
                    }
                }
                catch(e){
                    console.log(e);
                }

                this.loading_us = false;
            }
    }
    
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>