<template>
    <div id="work-view-wrapper">
        <SidebarComp></SidebarComp>
        <div id="snackbar-container">
            <!-- ERROR AND INFORMATION SNACKBARS -->
            <v-snackbar
                v-model="snackbar_error"
                :color="'error'"
                :timeout="5000"
                :top="true"
                >
                {{ snackbar_error_text }}
            </v-snackbar>
            <!--------------------------------->
        </div>
        <v-dialog v-model="show_save_artifact_dialog" max-width="400px">
            <v-card class="pa-6">
                <v-form ref="save_artifact_form" @submit.prevent="saveArtifact()">
                    <v-text-field class="work-textarea" outlined dense counter maxlength="50" v-model="new_artifact_name" label="Artifact Custom Name" :rules="[rules.required]"> </v-text-field> 
                    <v-btn color="primary" @click="saveArtifact()" :loading="loading_save" :disabled="artifact_saved"> Save </v-btn>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="user_leaving_dialog" persistent max-width="400px">
            <v-card>
                <v-card-text class="pt-6">You haven't saved this artifact. Are you sure you want to leave?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined text @click="confirmLeave()">Yes, discard this.</v-btn>
                    <v-btn text @click="user_leaving_dialog = false">No, let me save it.</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-main>
            <v-overlay :value="main_loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
            <v-container fluid class="v-scroll-y main-container breathing-room">
                <div id="document">
                    <v-card id="custom-text" class="px-12 py-8 mt-6 text-linebreak rounded-xl">
                        <v-btn class="mr-8 mt-8" absolute top right icon large @click="preSaveArtifact()" :disabled="show_save_artifact_dialog">
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                        <v-card-title class="ml-n2"> Artifact Text </v-card-title>
                        <v-textarea rows="10" class="work-textarea mt-2" outlined dense counter auto-grow maxlength="8000" v-model="new_artifact_content" :rules="[rules.required]"> </v-textarea>
                    </v-card>
                </div>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import SidebarComp from "@/components/SidebarComp.vue";
import {copyCardContent} from "@/utils/utils.js";
import {getUserStatus} from "@/services/users.js";
import {getProduct} from "@/services/products.js";
import {saveArtifactCustomText} from "@/services/artifacts/custom-text.js";

//const SUBSCRIBER_USER_ROLE_NAME = 'active';
const FREE_USER_ROLE_NAME = 'free';

export default {
    name: 'CustomTextView',

    props: ['product_id'],

    components: {
        SidebarComp
    },

    data: () => ({

        main_loading: false,

        user_status: FREE_USER_ROLE_NAME,

        product_name: '',
        product_description: '',

        loading_btn: false,

        new_artifact_name: '',
        new_artifact_content: '',

        rules: {
            required: value => !!value || 'Required',
        },

        //Snackbars
        snackbar_error: false,
        snackbar_error_text: '',

        show_save_artifact_dialog: false,

        loading_save: false,
        artifact_saved: false,

        user_leaving_dialog: false,
        can_leave: false,
        next_route: '',

    }),

    beforeRouteLeave(to, from, next) {
        if (this.artifact_saved|| this.can_leave || (!this.artifact_saved && !this.new_artifact_content)) {
            next();
        } else {
            this.user_leaving_dialog = true;
            this.nextRoute = to.path; 
        }
    },

    created(){
        this.getProduct();
        this.getUserStatus();
    },

    methods: {

        //UTILS
        copyArtifactContent(element_id){
            copyCardContent(element_id);
        },
        confirmLeave() {
            this.can_leave = true;
            this.user_leaving_dialog = false;
            this.$router.push(this.nextRoute); // navigate to the next route
        },
        preSaveArtifact(){
            this.show_save_artifact_dialog = true;
        },
        async saveArtifact(){
            if(this.$refs.save_artifact_form.validate()){
                this.loading_save = true;
                try{
                    const status = await saveArtifactCustomText(
                        {
                            "product_id": this.product_id
                        },
                        {
                            "artifact_name": this.new_artifact_name,
                            "artifact_data": {
                                "content": this.new_artifact_content
                            }
                        }
                    )

                    if(status == 200){
                        this.artifact_saved = true;
                        alert("Artifact Saved!");
                    }
                    else if(status == 401 || status == 403){
                        this.$router.push('/login');
                    }
                    else if(status == 500){
                        alert("Something wrong with the request.");
                        //push to 500 page
                    }
                    else{
                        alert("Something wrong with the request.");
                    }
                }
                catch(e){
                    console.log(e);
                    alert("Something wrong with the request.");
                }
                finally{
                    this.loading_save = false;
                    this.show_save_artifact_dialog = false;
                }
            }
        },

        //USER Functions
        async getUserStatus(){
            try{
                this.user_status = await getUserStatus();
            }
            catch(e){
                console.log(e);
            }
            
        },

        //PRODUCT Functions
        async getProduct(){
            let product_data;
            try{
                this.main_loading = true;
                product_data = await getProduct(this.product_id);
                this.product_name = product_data.product_name;
                this.product_description = product_data.product_description;
                this.main_loading = false;
            }
            catch(e){
                console.log(e);
                this.snackbar_error = true;
                this.snackbar_error_text = 'Error getting product data.';
            }

        },

        
        
    }
};

</script>

<style>

    .flex-container {
    display: flex;
    }

    .col-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    }

    .row-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    }

    .custom-row{
    overflow-y: auto;
    overflow-x: hidden;
    }

    .copilot-card{
        border-radius: 16px !important;
    }

    .work-textarea{
        border-radius: 16px !important;
    }

    .text-linebreak{
        white-space: pre-line;
    }

    .number-box{
        display: inline-block;
    }

    .gradient-icon{
    background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    }

    .save-container{
        display: flex;
        align-items: center;
    }

    .wrap-text {
    white-space: normal; /* Allow text to wrap to next line */
    word-wrap: break-word; /* Break words if necessary */
    }

    .main-container{
        overflow-y: auto !important;
        height: 100vh;
    }

    /* Default (XS) */
    .breathing-room {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }

    /* SM and up */
    @media (min-width: 600px) {
        .breathing-room {
            padding-left: 7% !important;
            padding-right: 7% !important;
        }
    }

    /* MD and up */
    @media (min-width: 960px) {
        .breathing-room {
            padding-left: 10% !important;
            padding-right: 10% !important;
        }
    }

    /* LG and up */
    @media (min-width: 1264px) {
        .breathing-room {
            padding-left: 20% !important;
            padding-right: 20% !important;
        }
    }

    /* XL and up */
    @media (min-width: 1904px) {
        .breathing-room {
            padding-left: 25% !important;
            padding-right: 25% !important;
        }
    }

</style>