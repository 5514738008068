<template>
<div>
  <SidebarComp></SidebarComp>
  <v-main>
    <v-overlay :value="main_loading">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
    </v-overlay>
    <v-container fluid class="breathing-room main-container">   
        <div id="intro-div">
          <v-row class = "pt-12">
            <v-col cols="12 d-flex">
              <h2 class="pl-2"> 👋 Hey! </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols = "12 d-flex">
              <v-card width ="100%">
                <v-card-title>
                  Getting Started
                </v-card-title>
                <v-card-text>
                  {{card_header_text}}
                </v-card-text>
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(item,i) in onboarding_tasks"
                    :key="i"
                  >
                    <v-expansion-panel-header class="title-task">{{item.title}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="text--secondary">{{item.description}}</p>
                      <v-list>
                        <v-list-item v-for="(step, j) in item.steps" :key="j">
                          <v-list-item-icon>
                            {{j+1}}.
                          </v-list-item-icon>
                          <v-list-item-content>
                            {{step.description}} 
                          </v-list-item-content>
                        </v-list-item>
                        <!--
                        <v-list-item>
                          <v-list-item-icon>
                            ✅
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span 
                              @click="completeTask(item)"
                              class="text--secondary "
                              style="text-decoration: underline; cursor: pointer;"
                            > 
                              Mark as complete 
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                      -->
                      </v-list>
                      
                      
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-col>
          </v-row>
        </div>
    </v-container>
  </v-main>
</div>
</template>

<script>
import SidebarComp from '@/components/SidebarComp.vue';
import {getUserDetails} from '@/services/users.js';

export default {
  name: 'ProductView',

  components: {
    SidebarComp,
  },

  data: () => ({
    activeCard: false,
    main_loading: false,
    dialog: false,
    products: {},
    user_name: '',
    card_header_text: 'Explore pmcopilot.ai by completing the suggested tasks below.',
    onboarding_tasks: [
      {
        'id': 'create-product',
        'title': '🚀 Create a product',
        'description': 'Your product is the basis for all the tools you can find here. \
                        It can be a small vertical that you are focusing or the whole platform your team manages, \
                        you decide what suits you the best. Keep in mind that the information that you attach to your product \
                        will be used by all the tools so be as correct and as detail you can be on this.',
        'steps': [
          {'description': 'Go to Products in the left menu'},
          {'description': 'Create new product'},
          {'description': 'Fill some of the details about your product'},
          {'description': 'Click on the product card just created to use it as your working product'}
        ],
        'completed': false
      },
      {
        'id': 'import-diagram',
        'title': '⬇️ Import Figjam Diagram',
        'description': 'To help you write better requirements and user stories you can use your Figjam diagrams for that.',
        'steps': [
          {'description': 'Chose a simple diagram in Figjam, with nothing but squares and connectors'},
          {'description': 'Go to Artifacts on the side menu, select Import Diagram as a new artifact'},
          {'description': 'Follow the instructions there until you have generated the Secret Key'},
          {'description': 'At Figma, lookup pmcopilot.ai plugin, open it and follow the instructions there'},
          {'description': 'If you refresh the Artifacts page you should find your diagram there'},

        ],
        'completed': false
      },
      {
        'id': 'create-epic',
        'title': '⚡️ Create Epic',
        'description': 'After you have created a product and import a diagram you can now generate an Epic with some User Stories. \n 📝 Note: Importing a Diagram is optional to generate an Epic.',
        'steps': [
          {'description': 'Go to Artifacts on the side menu, select Epic Writer as a new artifact'},
          {'description': 'Describe your new feature and attach a Diagram'},
          {'description': 'Your Copilots will ask you follow up questions to improve the document'},
          {'description': 'Click Generate!'},

        ],
        'completed': false
      },
      {
        'id': 'task-prioritization',
        'title': '⇈ Task Prioritization',
        'description': 'After creating your product you are able to get help prioritizing tasks.',
        'steps': [
          {'description': 'Go to Operational on the side menu, select Task Priority as a new operational job.'},
          {'description': 'Describe and contextualize your problem'},
          {'description': 'Your Copilots will ask you follow up questions to improve the decision.'},
          {'description': 'Get a Decision.'},

        ],
        'completed': false
      }
    ]

  }),

  created(){
    //this.getProducts();
    this.getUserDetails();

  },

  methods:{
    async getUserDetails(){
      let user = await getUserDetails();
      this.user_name = user.name;

    },
    //TODO: Use products module (still need to develop)
    async getProducts(){
      this.main_loading = true;
      try{
        const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products?order=desc';
        const response = await fetch(base_url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
            }
        });

        // If status success (code 200)
        if(response.status != 200){
            throw Error('Error getting Products.');
        }
        else{
          this.products = await response.json();
          let active_product = this.getActive(this.products);
          this.changeProduct(active_product);
        }

      }
      catch(error){
        alert(error.message);
      }
      finally{
        this.main_loading = false;
      }
    },
    humanDate(robot_date){
      let date = new Date(robot_date);
      let humanReadableDate = date.toLocaleString();
      return humanReadableDate;
    },
    comingSoon(){
      alert("Feature Coming Soon!");
    }
  }
};

</script>

<style scoped>
.product-card {
    border-radius: 16px !important;
    cursor: pointer !important;
    /*height: 100px !important;*/
    width: 300px !important;
}

.gradient-icon{
   background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.inactive-card-title{
    color: gray;
}

.active-text{
    color: black !important;
}

.gradient-card-title{
   background: linear-gradient(to right, #27374D  0%, #007232 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.hovered{
    border: 2px solid #007232 !important;
}

.card-title {
  white-space: nowrap;      /* Prevents the text from wrapping onto next line */
  overflow: hidden;         /* Hides any text that overflows the containing element */
  text-overflow: ellipsis !important;  /* Shows an ellipsis (...) when text overflows */
}

.h3-title{
  color: #62758f !important;
}

/* Default (XS) */
.breathing-room {
padding-left: 5% !important;
padding-right: 5% !important;
}

/* SM and up */
@media (min-width: 600px) {
.breathing-room {
    padding-left: 7% !important;
    padding-right: 7% !important;
}
}

/* MD and up */
@media (min-width: 960px) {
.breathing-room {
    padding-left: 10% !important;
    padding-right: 10% !important;
}
}

/* LG and up */
@media (min-width: 1264px) {
.breathing-room {
    padding-left: 20% !important;
    padding-right: 20% !important;
}
}

/* XL and up */
@media (min-width: 1904px) {
.breathing-room {
    padding-left: 25% !important;
    padding-right: 25% !important;
}
}

.main-container{
        overflow-y: auto !important;
        height: 100vh;
}

.title-task{
  font-size: 1rem !important;
}


</style>