<template>
<v-layout align-center justify-center>
    <v-overlay :value="loading">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
    </v-overlay>
    <!-- Terms and Privacy Policy Modal -->
    <v-dialog v-model="terms_dialog" max-width="400px">
      <v-card class="pb-2">
        <v-card-title> </v-card-title>
        <v-card-text>
            By continuing, you agree to our 
            <a href="https://app.pmcopilot.ai/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>,
            and to our 
            <a href="https://app.pmcopilot.ai/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </v-card-text>
        <div class = "ml-2">
            <v-card-actions>
            <v-btn color="gray" text @click="terms_dialog = false">Decline</v-btn>
            <v-btn color="primary" text outlined @click="terms_dialog = false; proceedWithGoogleLogin();">Accept</v-btn>
            </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-flex class="justify-center" style="width:100%">
        <div class="py-8">
            <v-row class="justify-center mb-n4">
                <v-col class="d-flex align-center justify-center">
                    <v-img
                        alt="Logo"
                        contain
                        src="../assets/pm_copilot_sq.png"
                        transition="scale-transition"
                        max-width="60"
                    />
                    
                    <h1 class="ml-6">pmcopilot.ai</h1>
                </v-col>
            </v-row>

            <v-row class="mb-n4">
                <v-col class="d-flex align-center justify-center" cols="12">
                    <v-form v-model="valid" ref="loginForm">
                        <v-text-field
                            class="consistent-width"
                            v-model="email"
                            :rules ="emailRules"
                            label="E-mail"
                            required >
                        </v-text-field>
                        <v-text-field
                            class="consistent-width"
                            v-model="password"
                            :rules="passwordRules"
                            label="Password"
                            type="password"
                            required 
                        >
                        </v-text-field>
                    </v-form>
                </v-col>
            </v-row>

            <v-row class="px-6">
                <v-col cols="2" offset="5" class="d-flex align-center justify-center">
                    <v-btn class="btn-login" :disabled ="!valid" color ="#27374D" @click.prevent="emailLogin" outlined>
                        Login
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="px-6">
                <v-col cols="2" offset="5" offset-md="5" class="d-flex align-center justify-center">
                    <v-btn class="btn-login" @click.prevent="googleLogin()" outlined> 
                        <img width="20px" style="margin-bottom:3px; margin-right:5px" alt="Google sign-in" src="../assets/Google-favicon-2015.png" />
                        Continue with Google
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="px-16">
                <v-col class="d-flex align-center justify-center flex-column">
                    Don't have an account?
                    <router-link to ="/signup"> Sign up here</router-link>
                </v-col>
            </v-row>
        </div>
    </v-flex>
</v-layout>
</template>

<script>
//import { mapActions } from 'vuex';
import {firebase_auth_obj, firebase_auth} from '../plugins/firebase.js';

export default {
    name: 'RegisterScreen',

    data: () => ({
        email: '',
        emailRules: [
            v => !!v || "E-mail is required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        password: '',
        passwordRules: [
            v => !!v || "Password is required",
            v => v.length >= 6 || "Password must be at least 6 characters"
        ],
        snackbar: false,
        loading: false,
        email_register: false,
        valid: true,
        terms_dialog: false
    }),

    methods:{

        handleSignUp(){
        try{
          this.gtagReportConversion('/'); 
          return
        }
        catch(e){
          console.log("Error at the tracking.");
        }

        this.$router.push('/');
        
        },

        gtagReportConversion(route) {
            let callback = () => {
            if (typeof route !== 'undefined') {
                this.$router.push(route);
            }
            };

            window.gtag('event', 'conversion', {
            send_to: 'AW-11368247082/Jv74CL6KxuoYEKre5qwq',
            event_callback: callback
            });
        },

        async emailLogin(){
            if (this.$refs.loginForm.validate()) {
                this.loading = true;
                try{
                    await firebase_auth_obj.signInWithEmailAndPassword(firebase_auth, this.email, this.password);
                }
                catch(error){
                    alert(error.message);
                    this.loading = false;
                    return;
                }
                this.loading = false;
                this.$router.push('/');
            }
        },

        async proceedWithGoogleLogin(){
            this.loading = true;
            const googleProvider = new firebase_auth_obj.GoogleAuthProvider();
            try {
                const auth_result = await firebase_auth_obj.signInWithPopup(firebase_auth, googleProvider);
                const newUser = firebase_auth_obj.getAdditionalUserInfo(auth_result).isNewUser;
                if(newUser){
                    const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/users/new';
                    const response = await fetch(base_url, {
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            user_id: String(auth_result.user.uid),
                            email: String(auth_result.user.email),
                            method: 'Google',
                            user_data: {name: auth_result.user.displayName}
                        })
                    });

                    // If status success (code 200)
                    if(response.status != 200){
                        throw Error('Error creating user.');
                    }
                    else{
                        this.loading = false;
                        this.$router.push('/');
                    }

                }
                else{
                    this.loading = false;
                    this.$router.push('/');
                }
            } catch(error){
                alert(error.message);
                this.loading = false;
                return;
            }
        },

        async googleLogin() {
            if(!this.$cookies.isKey(process.env.VUE_APP_TOKEN_KEY)){
                this.terms_dialog = true;
            }
            else{
                this.proceedWithGoogleLogin();
            }          
        }

    }
}
</script>

<style scoped>

.btn-login {
    min-width: 300px !important;
}

.consistent-width {
    min-width: 300px !important;
}

</style>