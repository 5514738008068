<template>
<div v-if="gotUserId">
    <stripe-pricing-table 
        :pricing-table-id="pricing_table_id" 
        :publishable-key="p_key" 
        :client-reference-id="client_reference_id">
    </stripe-pricing-table>
</div>
</template>

<script>

import VueCookies from 'vue-cookies';

  export default {
    name: 'PriceCard',

    data: () => ({
        pricing_table_id: process.env.VUE_APP_STRIPE_PRICING_TABLE_ID,
        p_key: process.env.VUE_APP_STRIPE_PK,
        client_reference_id: '',
        gotUserId: false
    }),

    async mounted(){
        const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/users';
        try{
            const response = await fetch(base_url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + VueCookies.get(process.env.VUE_APP_TOKEN_KEY)
                },
            });

            if(response.status == 200){
                const content = await response.json();
                this.client_reference_id = content.user_id;
                this.gotUserId = true;
            }
            else {throw new Error('Could not load the right pricing table.');}


        }
        catch(e){
            alert('Error getting user id.')
            return;
        }
    }
}
</script>