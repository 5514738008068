<template>
    <nav>
      <v-navigation-drawer ref="drawer" app expand-on-hover width="230px" color="#27374D" class="d-flex fill-height" permanent>
        <div class="drawer-container">
          <v-list
          dense
          nav
          >
            <div class="fill-height">
              <v-list-item class="my-4">
                <div class="ml-n2">
                  <v-img
                    alt="Logo"
                    contain
                    src="../assets/pm_copilot_sq.png"
                    transition="scale-transition"
                    width="38"
                  />
                </div>
                <v-list-item-content>
                  <v-list-item-title>
                    <h1 class="h1-text-white ml-2">pmcopilot.ai</h1>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                class ="nav-list-item-main"
                @click="$router.push(item.link)"
              >
                <v-list-item-icon>
                  <v-icon :color="item.color" size="20px">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content class="nav-list-item-content-main ml-n4">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class ="nav-list-item-main" link href="https://go.crisp.chat/chat/embed/?website_id=65591975-d486-4ff9-b7d2-a4aff36407f4" target="_blank" rel="noopener noreferrer">
                <v-list-item-icon> <v-icon color="white" size="20px"> mdi-chat </v-icon> </v-list-item-icon>
                <v-list-item-content class="nav-list-item-content-main ml-n4">
                  <v-list-item-title>Feedback</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </div>

            <!--
            <v-list-item 
              v-if="has_product" 
              class ="nav-list-item-main fixed-bottom mb-12" 
              link 
              @click="$router.push('/products')"
              @mouseover="product_tooltip = true" 
              @mouseleave="product_tooltip = false">
              <v-list-item-icon> 
                <v-icon 
                  size="20px"
                  :color="productIconColor" 
                  :class="productIconClass"
                >
                  {{ productIconName }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="nav-list-item-content-main">
                <v-list-item-title :class="{'white--text': !activeProduct}">
                  {{ activeProduct ? activeProduct.product_name : 'Select a Product' }}
                </v-list-item-title>
              </v-list-item-content>

              <v-tooltip v-model="product_tooltip" bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on"></span>
                </template>
                <span>{{ activeProduct ? activeProduct.product_name : 'Select a Product' }}</span>
              </v-tooltip>
            </v-list-item>
            -->

          </v-list>
        
      </div>  
      </v-navigation-drawer>

    </nav>
</template>

<script>

export default {
  name: 'SidebarComp',

  data: () => ({

    items: [
          {title: 'Dashboard', icon: 'mdi-view-dashboard', color: 'white', 'link': '/'},
          {title: 'Products', icon: 'mdi-sprout', color: 'white', 'link': '/products'},
          {title: 'Artifacts', icon: 'mdi-palette', color: 'white', link: '/artifacts'},
          {title: 'Operational', icon: 'mdi-factory', color: 'white', link: '/operational'},
          {title: 'Settings', icon: 'mdi-cog', color:'white', link:'/settings'}
        ],
    has_product: false,
    product_tooltip: false
  }),

  created(){

  },

  methods: {
    //TODO: develop a util for this
    /*
    async getActiveProduct(){
      try{
        const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products';
        const response = await fetch(base_url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
            }
        });

        // If status success (code 200)
        if(response.status != 200){
            throw Error('Error getting Products.');
        }
        else{
          this.products = await response.json();
          let active_product = this.getActive(this.products);
          this.changeProduct(active_product);
          this.has_product = true;
        }

      }
      catch(error){
        alert(error.message);
      }
      finally{
        this.main_loading = false;
      }
    },
    getActive(arr) {
      for (let obj of arr) {
        if (obj.active) {
          return obj;
        }
      }
      return null; // or return -1, or any other value to indicate no active element was found
    },
    changeProduct(product){
      this.$store.dispatch('setActiveProduct', product);
    }
    */

  },

  computed: {
    activeProduct() {
      return this.$store.getters.activeProduct;
    },
    productIconName() {
      return this.activeProduct ? 'mdi-sprout' : 'mdi-cancel';
    },
    productIconColor() {
      return this.activeProduct ? '#007232' : 'gray';
    },
    productIconClass() {
      return this.activeProduct ? 'white-bg' : 'white-bg';
    }
  }



};
</script>

<style>
.full-width{
    width: 100%;
}

.nav-list-item-content-main {
  color: white;
}
.nav-list-item-main:hover {
  color: #27374D;
  background-color: #9db2bf9f;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.nav-list-item-main:not(:hover) {
  color: white;
  background-color:#27374D;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-action-btn .v-btn__content {
  text-transform: none;
  color: #27374D;
}

.h1-text-white{
  color:white;
}

.drawer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.fixed-bottom {
  position: fixed !important;
  bottom: 0 !important;
  width: 220px;
}

.white-bg {
  background-color: white;
  border-radius: 10%; 
}

</style>