const firebase_app = require('firebase/app');
const firebase_auth_obj = require("firebase/auth");

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FBASE_API_KEY,
    authDomain: process.env.VUE_APP_FBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FBASE_APP_ID,
    measurementId: process.env.VUE_APP_FBASE_MEASURE_ID
  };

const firebase = firebase_app.initializeApp(firebaseConfig);
const firebase_auth = firebase_auth_obj.getAuth(firebase);

module.exports = {firebase, firebase_auth, firebase_auth_obj};