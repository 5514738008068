import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router/router.js'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {firebase_auth} from './plugins/firebase';
import '@mdi/font/css/materialdesignicons.css'
import store from './store/store.js';
import * as Sentry from "@sentry/vue";

const DEV_SESSION_REPLAY_RATE = 0;
const PROD_SESSION_REPLAY_RATE = 0.25;
const PROD_ERROR_SESSION_REPLAY_RATE = 1;

Vue.use(VueRouter);
Vue.use(VueCookies, {expire: '7d'});

firebase_auth.onIdTokenChanged((user) => {
  if (user) {
    user.getIdToken(true).then((idToken) => {
      Vue.$cookies.set(process.env.VUE_APP_TOKEN_KEY, idToken);
    });

  } else {
    Vue.$cookies.remove(process.env.VUE_APP_TOKEN_KEY);
  }
});

var replaysSessionSampleRate;
var replaysOnErrorSampleRate;

if(process.env.VUE_APP_SENTRY_ENV === "dev"){
  replaysSessionSampleRate = DEV_SESSION_REPLAY_RATE;
  replaysOnErrorSampleRate = DEV_SESSION_REPLAY_RATE;
}
else{
  replaysSessionSampleRate = PROD_SESSION_REPLAY_RATE;
  replaysOnErrorSampleRate = PROD_ERROR_SESSION_REPLAY_RATE;
}

Sentry.init({
  Vue,
  dsn: "https://99d11b1eb0090d16b23fe796778bb14d@o4505781900083200.ingest.sentry.io/4506384022962176",
  environment: process.env.VUE_APP_SENTRY_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.pmcopilot\.ai/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: replaysSessionSampleRate,
  replaysOnErrorSampleRate: replaysOnErrorSampleRate,
});

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
