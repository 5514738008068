<template>
  <v-dialog v-model="dialog_show" max-width="600px" persistent>
    <v-card>
        <v-btn absolute top right icon small @click="closeDialog()" style="z-index: 10;">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      <v-card-title>{{ object_document.operational_name }}</v-card-title>
      <v-card-text>
        <div><p><strong>Decision Context</strong><br> <span class="text--primary">{{ object_document.decision_context }}</span></p></div>
        <div><p><strong>Decision Style</strong><br> <span class="text--primary">{{ object_document.decision_style }}</span></p></div>

        <div><strong>Tasks</strong></div>
        <v-list>
          <v-list-item v-for="(task, index) in object_document.tasks" :key="index">
            {{ task.task }}
          </v-list-item>
        </v-list>

        <v-divider class="my-2"></v-divider>
        
        <div><strong>Q&A List</strong></div>
        <v-list>
            <v-list-item v-for="(qa, index) in object_document.qa_list" :key="index">
                <v-list-item-content>
                <p>
                    <strong>Q:</strong> {{ qa.question }}
                </p>
                <p>
                    <strong>A:</strong> {{ qa.answer }}
                </p>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider class="my-2"></v-divider>

        <div v-if="object_document.decisions">
            <strong>Decision</strong><br>{{ object_document.decisions.decision }}
            <v-list>
                <v-list-item v-for="(reason, index) in object_document.decisions.reasons" :key="index">
                    <v-list-item-content>
                        - {{ reason.reason }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['value', 'object'],

    data() {
        return {
            dialog_show: this.value,
            object_document: {}
        }
    },
    created(){
        this.object_document = this.object;
    },
    methods: {
        closeDialog() {
            this.$emit('close-dialog');
        },
    },
    watch: {
        value(newVal) {
            this.dialog_show = newVal;
        },
        object(newVal){
            this.object_document = newVal;
        }
    }
};
</script>

<style>
/* Add custom styles here */
</style>