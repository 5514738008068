<template>
    <div>
        <v-dialog v-model="delete_dialog" persistent max-width="300px">
            <v-card class="pb-2">
                <v-card-title>Confirmation</v-card-title>
                <v-card-text>Are you sure you want to delete this artifact?</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="deleteArtifact()">Yes</v-btn>
                <v-btn color="primary" text @click="delete_dialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card id="custom-text-card" max-width="600px" class="pa-2 text-linebreak rounded-xl">
            <v-btn absolute top right icon small @click="closeDialog()" style="z-index: 2;">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn absolute top right icon small @click="copyCardContent()" style="z-index: 2;" class="mr-6">
                <v-icon color="gray" :style="{'transform': 'rotate(90deg)'}"> mdi-card-multiple </v-icon>
            </v-btn>
            <v-btn absolute top right icon small @click="deleteArtifactDialog()" style="z-index: 2;" class="mr-12">
                <v-icon color="gray"> mdi-delete </v-icon>
            </v-btn>
            <v-card-text>{{dialog_document}}</v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'CustomTextViewerDialog',

    props: ['document', 'artifact_id'],

    data: () => ({

        dialog_document: {},
        delete_dialog: false

    }),

    created(){
        this.dialog_document = this.document;
    },

    methods:{
        closeDialog(){
            this.$emit('close-dialog');
        },
        copyCardContent() {
                // Get the v-card element
                const card = document.querySelector('#custom-text-card');

                // Get the text content of the v-card
                const content = card.textContent;

                // Use the Clipboard API to write the text content to the clipboard
                navigator.clipboard.writeText(content)
                    .then(() => {
                    alert("Text Copied to the Clipboard.");
                    })
                    .catch((error) => {
                    console.error('Failed to copy text: ', error);
                    });
        },

        deleteArtifactDialog(){
            this.delete_dialog = true;
        },

        async deleteArtifact(){
            try{
                const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/' + this.artifact_id;
                const response = await fetch(base_url, {
                    method: 'DELETE',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                    }
                });

                if(response.status != 200){
                    throw Error('Error deleting Artifacts.');
                }
                else if(response.status == 200){
                    this.delete_dialog = false;
                    this.$emit('artifact-deleted', this.artifact_id);
                    this.$emit('close-dialog');
                }
            }
            catch(error){
                alert('Error getting Artifacts.');
            }
        }   
    }
    
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>