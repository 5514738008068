<template>
    <div>
        <SidebarComp></SidebarComp>
        <div id="snackbar-container">
            <!-- ERROR AND INFORMATION SNACKBARS -->
            <v-snackbar
                v-model="snackbar_error"
                :color="'error'"
                :timeout="5000"
                :top="true"
                >
                {{ snackbar_error_text }}
            </v-snackbar>
            <!--------------------------------->
        </div>

        <!-- New Operational Dialog -->
        <NewOperationalDialog v-model="new_operational_dialog" @selected-new-operational="handleSelectedOperational" @close-dialog="closeNewOperational()"> </NewOperationalDialog>

        <!-- VIEW OPERATIONAL JOB DIALOG -->
        <ViewTaskPriorityDialog v-model="task_priority_dialog" :object="show_operational_job" @close-dialog="closeTaskPriorityView()"> </ViewTaskPriorityDialog>

        <v-main>
            <v-overlay :value="main_loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
                <v-container fluid class="pt-12 pl-12 pr-12 v-scroll-y main-container breathing-room">      
                    <div id="product-header">
                        <v-row>
                            <v-col cols = "12 d-flex">
                                <h1 class="mt-2"> {{product.product_name}}</h1> 
                            </v-col>
                        </v-row>
                        <v-row id="product-actions" class="mt-n6">
                            <v-col cols = "12 d-flex">
                                <router-link :to="'/settings/product/' + id">
                                    <span class="text--secondary"> Edit </span>
                                </router-link>
                            </v-col>
                        </v-row>
                    </div>
                    <div id="operational-section">
                        <v-row class = "mt-8">
                            <v-col cols="12 d-flex">
                                <h2> Operational </h2> 
                                <v-spacer> </v-spacer> 
                                    <v-chip
                                    color="black"
                                    outlined
                                    @click="new_operational_dialog = true"
                                    >
                                        <v-icon left>
                                        mdi-plus-circle
                                        </v-icon>
                                        New Operational Job
                                    </v-chip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" v-if="operationals && operationals.length === 0">
                                <h4 class="text--secondary"> You haven't created any operational jobs for this product 🧐 </h4>
                            </v-col>

                            <v-col cols="12">
                                <v-data-table 
                                    hide-default-header
                                    :headers="headers"
                                    :items="operationals"
                                    @click:row="showOperational">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
        </v-main>
    </div>
</template>

<script>
import SidebarComp from '@/components/SidebarComp.vue';

import NewOperationalDialog from '@/components/operational/NewOperationalDialog.vue';
import ViewTaskPriorityDialog from '@/components/operational/ViewTaskPriorityDialog.vue';

import {getProduct} from "@/services/products.js";
import {getOperational} from "@/services/operational.js";


export default {
    name: 'OperationalView',

    components: {
        SidebarComp,
        NewOperationalDialog,
        ViewTaskPriorityDialog
    },

    //props: ['id'],

    data: () => ({

        product: {
            product_name: ''
        },

        id: '',

        main_loading: true,

        operationals: [ ],

        headers: [
            { text: 'Name', value: 'operational_name' },
            { text: 'Type', value: 'operational_type' },
            { text: 'Last Updated', value: 'updated' },
            
        ],

        snackbar_error: false,
        snackbar_error_text: '',

        new_operational_dialog: false,

        delete_dialog: false,

        show_operational_job: {},
        task_priority_dialog: false

    }),

    async mounted(){
        let product = await this.getActiveProduct();
        if(product){
            this.id = product.product_id;
            this.getProduct();
            this.getOperational();
        }
        else{
            alert('Select or Create a Product first.');
            this.$router.push('/products');
        }
        this.main_loading = false;
    },
    

    methods: {

        async getActiveProduct() {
            // If it's not set, fetch it
            if (!this.$store.getters.activeProduct) {
                await this.$store.dispatch('fetchActiveProduct');
            }

            return this.$store.getters.activeProduct;
        },
        
        //UTILS
        humanDate(robot_date){
            let date = new Date(robot_date);
            let humanReadableDate = date.toLocaleString();
            return humanReadableDate;
        },

        //PRODUCT Functions
        async getProduct(){
            let product_data;
            try{
                product_data = await getProduct(this.id);
                this.product = product_data;
            }
            catch(e){
                console.log(e);
                this.snackbar_error = true;
                this.snackbar_error_text = 'Error getting product data.';
            }
        },
        
        //Operational Functions
        //this can be modularized
        async getOperational(){

            let operational;

            try{
                operational = await getOperational({"product": this.id, "order": "desc"});
                this.operationals = operational.map(item => {
                        item.updated = this.humanDate(item.updated);
                        return item;
                });
            }
            catch(e){
                alert('Error getting Operational.');
            }

        },
        closeNewOperational(){
            this.new_operational_dialog = false;   
        },
        handleSelectedOperational(operational_obj){
            this.$router.push(operational_obj.operational_url + this.id);
            return;
        },
        showOperational(item){
            let operational_data_json;
            
            try{
                operational_data_json = JSON.parse(item.operational_data);
            }
            catch(e){
                alert("Error getting operational data");
                return;
            }

            if(item.operational_type === 'TASK-PRIORITY'){
                
                this.show_operational_job = operational_data_json.content;
                this.show_operational_job.operational_name = item.operational_name;
                this.task_priority_dialog = true;
            }


        },
        closeTaskPriorityView(){
            //do this more abstractly
            this.task_priority_dialog = false;
        }
        //---TODO for Operational----
        /*
        
        ,
        handleArtifactDeletion(artifact_id){
            this.artifacts = this.artifacts.filter(artifact => artifact.artifact_id !== artifact_id);
        },
        
        */

    },

    
}
</script>

<style scoped>
    .main-card {
        border-radius: 16px !important;
        cursor: pointer !important;
    }

    .gradient-icon{
    background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    }

    .inactive-card-title{
        color: gray;
    }

    .active-text{
        color: black !important;
    }

    .gradient-card-title{
    background: linear-gradient(to right, #27374D  0%, rgb(0, 114, 50) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    }

    .hovered{
        border: 2px solid #007232 !important;
    }

    .scrollable-cards {
        overflow-x: auto;
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */
    }

    .scrollable-cards::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }

    .main-container{
        overflow-y: auto !important;
        height: 100vh;
    }

    .link-style {
        text-decoration: underline; /* Add an underline */
        cursor: pointer; /* Change the cursor to a pointer */
    }

    /* Default (XS) */
    .breathing-room {
    padding-left: 5% !important;
    padding-right: 5% !important;
    }

    /* SM and up */
    @media (min-width: 600px) {
    .breathing-room {
        padding-left: 7% !important;
        padding-right: 7% !important;
    }
    }

    /* MD and up */
    @media (min-width: 960px) {
    .breathing-room {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
    }

    /* LG and up */
    @media (min-width: 1264px) {
    .breathing-room {
        padding-left: 20% !important;
        padding-right: 20% !important;
    }
    }

    /* XL and up */
    @media (min-width: 1904px) {
    .breathing-room {
        padding-left: 25% !important;
        padding-right: 25% !important;
    }
    }



</style>