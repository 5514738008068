import Vue from 'vue';
import Vuex from 'vuex';

import { getActiveProduct } from "@/services/products";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeProduct: null
  },
  mutations: {
    SET_ACTIVE_PRODUCT(state, product) {
      state.activeProduct = product
    }
  },
  actions: {
    setActiveProduct({ commit }, product) {
      commit('SET_ACTIVE_PRODUCT', product)
    },
    async fetchActiveProduct({ commit }) {
      let product = await getActiveProduct(); 
      commit('SET_ACTIVE_PRODUCT', product);
  }
  },
  getters: {
    activeProduct: state => state.activeProduct
  }
})