<template>
<v-dialog v-model="dialog_show" max-width = "520px">
    <v-sheet rounded class="dialog-sheet py-12 pl-2">
        <v-btn absolute top right icon small @click="closeDialog()" style="z-index: 10;">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-row justify="center" align="center" class="mb-6">
            <h2> Select operational job </h2>
        </v-row>
        <v-row justify="center" align="center" class="mb-n4">
            <v-col v-for="(item, key) in operational_job_list" :key="key" cols="6" class="mr-n10">
                <v-hover v-slot="{hover}">
                    <v-card
                    elevation="1" 
                    outlined max-width="200px" min-width="200px" min-height="150px" 
                    class="main-card mb-2" 
                    :class="{'hovered': hover, '': !hover}" 
                    @click="selectOperational(item);"
                    >
                    <v-card-title class="small-font">
                        <v-icon size="16" left :class="{'gradient-icon': hover}">{{item.operational_icon}}</v-icon>
                        <span :class="{'gradient-card-title': hover, 'inactive-card-title': !hover}">{{item.operational_type}}</span>
                    </v-card-title>
                    <v-card-text class="mt-n4" :class="{'gradient-card-title': hover}">
                        {{item.operational_description}} 
                    </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-sheet>
</v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
  data() {
    return {
        dialog_show: this.value,
        stepper: 1,
        operational_job_list: [
            {
                "operational_type": "Task Priority",
                "operational_icon": "mdi-tray-full",
                "operational_url": "/task-priority/",
                "operational_description": "Your copilot helps you prioritize tasks, features and issues."
            }
        ],
        selected_operational: null,
        step_description: [],
        rules: {
            required: v => !!v || 'This field is required.'
        }
        };
  },
  methods: {
    closeDialog() {
        this.$emit('close-dialog');
    },
    selectOperational(item) {
        //The item object should be emitted to the parent component
        this.$emit('selected-new-operational', item);
        this.closeDialog();
    }
  },
    watch: {
        value(newVal) {
            this.dialog_show = newVal;
        }
    }
};
</script>

<style scoped>
.small-font {
  font-size: 15px;  /* Adjust as per your need */
}

.dialog-sheet {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}
</style>