import { getUserAuthToken } from "@/utils/cookies";

const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts';

export async function getArtifacts(query){
    let urlParameters = Object.keys(query).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
    });

    const url = base_url + '?' + urlParameters.join('&');
    
    const response = await fetch(url, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getUserAuthToken()
        }
    });

    if(response.status != 200){
        throw Error('Error getting artifacts.');
    } else {
        const content = await response.json();
        return content;
    }
}

export async function generateTextArtifact(/*artifact_type, product_id, context*/){
    //This function will generate a first version of the text-based artifact, with the selected context
    //This will be ready to be displayed with the new text, block-based editor.
}