import { getUserAuthToken } from "@/utils/cookies";

const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/operational';

export async function getOperational(query){
    let urlParameters = Object.keys(query).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
    });

    const url = base_url + '?' + urlParameters.join('&');
    
    const response = await fetch(url, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getUserAuthToken()
        }
    });

    if(response.status != 200){
        throw Error('Error getting operational.');
    } else {
        const content = await response.json();
        return content;
    }
}

export async function getTaskPriorityQuestions(prod_desc, decision_context, decision_style, tasks){

    const url = base_url + '/task-priority/questions';

    const response = await fetch(url, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getUserAuthToken()
        },
        body: JSON.stringify({
            "prod_desc": prod_desc,
            "decisiom_context": decision_context,
            "decision_style": decision_style,
            "tasks": tasks
        })
    });

    if(response.status != 200){
        throw Error('Error getting operational.');
    } else {
        const content = await response.json();
        return content;
    }
}

export async function getTaskPriorityDecisions(prod_desc, decision_context, decision_style, tasks, qa_list){

    const url = base_url + '/task-priority/generate';

    const response = await fetch(url, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getUserAuthToken()
        },
        body: JSON.stringify({
            "prod_desc": prod_desc,
            "decisiom_context": decision_context,
            "decision_style": decision_style,
            "tasks": tasks,
            "qa_list": qa_list
        })
    });

    if(response.status != 200){
        throw Error('Error getting operational.');
    } else {
        const content = await response.json();
        return content;
    }
}

export async function saveTaskPriority(product_id, operational_name, operational_content){

    const url = base_url + '/task-priority';

    const response = await fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getUserAuthToken()
            },
            body: JSON.stringify({
                "product_id": product_id,
                "operational_name": operational_name,
                "operational_content": operational_content
            })
        });
  
    const content = await response.json();
  
    if(response.status == 401 || response.status == 403){
        throw Error("Unauthorized");
    }
    if(response.status == 500){
        throw Error("Something went wrong")
    }
  
    return content.operational_id;
  
  }