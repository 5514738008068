<template>
<div>
  <SidebarComp></SidebarComp>
  <v-main>
    <v-container fluid class="pl-12 pr-12">   
      <h2 class="ml-2 mt-6 mb-6"> Settings </h2>
        <v-row>
          
          <v-col cols="12 d-flex">
            
            <v-hover v-slot="{hover}">
            <v-card  outlined max-width="350" class="main-card mr-6" :class="{'hovered pa-4': hover, 'pa-4': !hover}" @click="$router.push('/pricing')">
                <v-card-title>
                    <v-icon left :class="{'gradient-icon': hover}">fi-sr-rocket-lunch</v-icon>
              <span :class="{'gradient-card-title': hover, 'inactive-card-title': !hover}">Subscribe</span>
                </v-card-title>
                    <v-card-text :class="{'gradient-card-title': hover}">
                        Upgrade your account and generate unlimited documents.
                    </v-card-text>      
            </v-card>
            </v-hover>
            <v-hover v-slot="{hover}">
            <v-card outlined max-width="350" class="main-card mr-6" :class="{'hovered pa-4': hover, 'pa-4': !hover}" :href="stripe_portal" target="_blank" rel="noopener noreferrer">
                <v-card-title>
                    <v-icon left :class="{'gradient-icon': hover}">fi-sr-wallet</v-icon>
                    <span :class="{'gradient-card-title': hover, 'inactive-card-title': !hover}">Manage Subscription</span>
                </v-card-title> 
                <v-card-text :class="{'gradient-card-title': hover}">
                    Manage your pmcopilot.ai subscription.
                </v-card-text>  
            </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <!--
        <v-row>
          <v-col cols="12">
            <div class="image-container">
              <img src="../assets/spaceship.png" alt="Image" class="cropped-image" height="250px" width="auto">
            </div>
          </v-col>
        </v-row>
        -->
    </v-container>
  </v-main>
</div>
</template>

<script>
import SidebarComp from '@/components/SidebarComp.vue';

export default {
  name: 'GeneralView',

  components: {
    SidebarComp
  },

  data: () => ({
    stripe_portal: process.env.VUE_APP_STRIPE_PORTAL_URL
  }),

  mounted() {

  }
};

</script>

<style>
.main-card {
    border-radius: 16px !important;
    cursor: pointer !important;
}

.gradient-icon{
   background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.inactive-card-title{
    color: gray;
}

.active-text{
    color: black !important;
}

.gradient-card-title{
   background: linear-gradient(to right, #27374D  0%, #007232 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.hovered{
    border: 2px solid #007232 !important;
}




</style>