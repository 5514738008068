import { getUserAuthToken } from "@/utils/cookies";

const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/users'

export async function getUserStatus(){
    const url = base_url + '/user-status';
    
    const response = await fetch(url, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getUserAuthToken()
            }
    });

    const content = await response.json();

    if(response.status == 200){
        return content.user_status;
    }
    else{
        throw Error("Could not get user status.");
    }
}

export async function getUserDetails(){
    const url = base_url + '/user-details';
    
    const response = await fetch(url, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getUserAuthToken()
            }
    });

    const content = await response.json();

    if(response.status == 200){
        return content;
    }
    else{
        throw Error("Could not get user status.");
    }
}

export async function getGenerateFreeUsageLeft(){
    const url = base_url + '/free-generate-usage';
    try{
        const response = await fetch(url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getUserAuthToken()
                }
        });

        const content = await response.json();

        if(response.status == 200){
            return Number(content.limit) - Number(content.usage);
        }
    }
    catch(e){
        console.log(e);
    }

    return 0
}

export async function getDiagramFreeUsageLeft(){
    const url = base_url + '/free-diagram-usage';
    try{
        const response = await fetch(url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getUserAuthToken()
                }
        });

        const content = await response.json();

        if(response.status == 200){
            return Number(content.limit) - Number(content.usage);
        }
    }
    catch(e){
        console.log(e);
    }

    return 0
}