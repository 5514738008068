import { getUserAuthToken } from "@/utils/cookies";

const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/custom-text';

export async function saveArtifactCustomText(product, artifact){
    try{
        const response = await fetch(base_url, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getUserAuthToken()
                },
                body: JSON.stringify(
                    {
                        "product": {
                            "product_id": product.product_id
                        },
                        "artifact": {
                            "artifact_name": artifact.artifact_name,
                            "artifact_data": {
                                "content": artifact.artifact_data.content
                            }
                        }
                    })
        });
        return response.status;
    }
    catch(e){
        console.log(e);
        throw Error("Something wrong saving artifact.");
    }
}