<template>
    <div id="work-view-wrapper">
        <SidebarComp></SidebarComp>
        <v-dialog v-model="show_prod_init_save_name" max-width="400px">
            <v-card class="pa-6">
                <v-form ref="save_prod_init_name" @submit.prevent="saveProdInit()">
                    <v-text-field class="work-textarea" outlined dense counter maxlength="50" v-model="prod_init_name" label="Product Initiative Custom Name" :rules="[rules.required]"> </v-text-field> 
                    <v-btn color="primary" @click="saveProdInit()" :loading="loading_save" :disabled="prod_init_saved"> Save </v-btn>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="user_leaving_dialog" persistent max-width="400px">
            <v-card>
                <v-card-text class="pt-6">You haven't saved this artifact. Are you sure you want to leave?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined text @click="confirmLeave()">Yes, discard this.</v-btn>
                    <v-btn text @click="user_leaving_dialog = false">No, let me save it.</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-navigation-drawer id="action-drawer" app right width="400px" color="white" v-model="right_drawer">
        <v-list
            dense
            class="px-2"
            >
                <v-list-item class="my-4">
                    <h3> Explore a new Initiative for <br /> {{product_name}} </h3>
                </v-list-item>
                <div v-if="!hasQuestions">
                    <v-form ref="form">
                        <v-list-item class="mt-2">
                            <v-list-item-content>
                                <v-list-item-title>New Feature</v-list-item-title>
                                <v-text-field class="work-textarea mt-4" outlined dense counter maxlength="50" v-model="new_feature" :rules="[rules.required]"> </v-text-field>
                            </v-list-item-content>
                        </v-list-item> 
                        <v-list-item class="mt-2">
                            <v-list-item-content>
                                <v-list-item-title>Feature Description</v-list-item-title>
                                <v-textarea rows="2" class="work-textarea mt-4" outlined dense counter auto-grow maxlength="2000" v-model="feature_description" :rules="[rules.required]"> </v-textarea>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="mt-2" v-if="question_btn">
                            <v-list-item-content>
                                <v-btn block outlined :loading="loading_question_btn" rounded @click="getQuestions();"> Next </v-btn>
                            </v-list-item-content>
                        </v-list-item>
                    </v-form>
                </div>
                <div v-if="hasQuestions">
                    <div id="question-container" v-for="(q, i) in questions" :key="i">
                        <v-list-item class="my-2">
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap">{{q.question}}</v-list-item-title>
                                <v-textarea rows="2" class="work-textarea mt-2" outlined dense counter maxlength="300" max-width="80%" v-model="questions[i].answer"> </v-textarea>    
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <v-list-item class="my-2">
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap"> Your copilots will generate Epics that will cover your Product Initiative. </v-list-item-title>
                            <v-hover v-slot="{hover}">
                                <v-btn class="mt-4" block outlined :disabled="submit_disabled" :loading="loading_submit_btn" rounded @click="submitQuestions(); right_drawer = false;"> <v-icon left :class="{'gradient-icon': hover}"> fi-sr-rocket-lunch </v-icon> Go! </v-btn>
                            </v-hover>
                        </v-list-item-content>
                    </v-list-item>   
                </div>
            </v-list>
        </v-navigation-drawer>
        <div id="snackbar-container">
            <!-- ERROR AND INFORMATION SNACKBARS -->
            <v-snackbar
                v-model="snackbar_error"
                :color="'error'"
                :timeout="5000"
                :top="true"
                >
                {{ snackbar_error_text }}
            </v-snackbar>
            <v-snackbar
                v-model="snackbar_usage"
                :color="'orange'"
                :timeout="30000"
                :top="true"
                >
                As a free user you can generate {{generate_usage_left}} more artifacts.
            </v-snackbar>
            <!--------------------------------->
        </div>
        <v-main>
            <v-overlay :value="main_loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
            <v-btn absolute top right icon large @click="right_drawer = !right_drawer">
                <v-icon v-if="!right_drawer">mdi-arrow-left-thick</v-icon>
                <v-icon v-else>mdi-arrow-right-thick</v-icon>
            </v-btn>
            <div class="row-container">
                <v-row class="custom-row justify-center align-center">
                    <v-col cols="12">
                        <div class="col-container">
                            <div id="copilot-card" v-if="!hasQuestions">
                                <v-card flat class="copilot-card text-center">
                                    <v-img src="../assets/spaceship-card.png" max-width="350px"/>
                                    <v-card-text> Send your copilots on a quest. <br> They always come back with an answer. </v-card-text>
                                </v-card>
                            </div>
                                <div id="question-card-container" v-if="hasQuestions && !hasProdInitDraft" class="mb-6">
                                    <v-card flat class="questions-card mt-6 text-center" max-width="350px" transition="scale-transition" origin="center center">
                                        <v-img src="../assets/questions_asked_copilots.png" max-width="350px"/>
                                        <v-card-subtitle class="px-8"> Your copilots returned with some questions. <br> <v-icon class="mt-2">mdi-arrow-right-thick</v-icon> </v-card-subtitle>  
                                    </v-card>      
                                </div>
                            <div id="document" v-if="hasProdInitDraft" class="mb-6">
                                <v-card id="prod-init-draft-card" max-width="600px" min-width="600px" class="pa-2 mt-6 text-linebreak rounded-xl">
                                    <v-card-title> {{'Product Initiative' + '\n'}} <v-spacer></v-spacer> <v-icon class="mr-2" color="gray" @click="preSaveProdInit()" :disabled="show_prod_init_save_name"> mdi-content-save </v-icon> <v-icon color="gray" :style="{'transform': 'rotate(90deg)'}" @click="copyArtifactContent()"> mdi-card-multiple </v-icon> </v-card-title>   
                                    <div v-for="(ep, i) in document" :key="i">
                                        <v-card-subtitle><b>{{ep.epic_title + '\n'}}</b></v-card-subtitle>
                                        <div v-for="(req, i) in ep.requirements" :key="i">
                                            <v-card-text class="text--secondary mt-n4">{{req.description + '\n'}}</v-card-text>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-main>
    </div>
</template>

<script>
import SidebarComp from "@/components/SidebarComp.vue";
import {copyCardContent} from "@/utils/utils.js";
import {getUserStatus, getGenerateFreeUsageLeft} from "@/services/users.js";
import {getProduct} from "@/services/products.js";

const SUBSCRIBER_USER_ROLE_NAME = 'active';
const FREE_USER_ROLE_NAME = 'free';

export default {
    name: 'ProdInitWriterView',

    props: ['product_id'],

    components: {
        SidebarComp
    },

    data: () => ({

        main_loading: false,

        user_status: FREE_USER_ROLE_NAME,

        n_quest: 3,
        n_stories: 5,
        hasProdInitDraft: false,
        hasQuestions: false,

        product_name: null,
        product_description: null,
        new_feature: null,
        feature_description: null,

        loading_question_btn: false,
        loading_submit_btn: false,
        questions: null,
        answers: [],

        question_btn: true,
        submit_disabled: false,

        document: {},

        rules: {
            required: value => !!value || 'Required',
        },

        //Snackbars
        snackbar_error: false,
        snackbar_error_text: '',
        snackbar_usage: false,
        generate_usage_left: 0,

        show_prod_init_save_name: false,
        loading_save: false,
        prod_init_name: '',
        prod_init_saved: false,

        user_leaving_dialog: false,
        can_leave: false,
        next_route: '',

        right_drawer: true

    }),

    beforeRouteLeave(to, from, next) {
        if (this.prod_init_saved || this.can_leave || (!this.prod_init_saved && !this.hasProdInitDraft)) {
            next();
        } else {
            this.user_leaving_dialog = true;
            this.nextRoute = to.path; 
        }
    },

    created(){
        this.getProduct();
        this.getUserStatus();
        this.navDrawerConfig();
    },

    methods: {

        //UTILS
        copyArtifactContent(element_id){
            copyCardContent(element_id);
        },
        navDrawerConfig(){
            this.right_drawer = window.innerWidth > 800;
        },
        confirmLeave() {
            this.can_leave = true;
            this.user_leaving_dialog = false;
            this.$router.push(this.nextRoute); 
        },

        //USER Functions
        async getUserStatus(){
            try{
                this.user_status = await getUserStatus();
            }
            catch(e){
                console.log(e);
            }
            
        },

        //PRODUCT Functions
        async getProduct(){
            let product_data;
            try{
                this.main_loading = true;
                product_data = await getProduct(this.product_id);
                this.product_name = product_data.product_name;
                this.product_description = product_data.product_description;
                this.main_loading = false;
            }
            catch(e){
                console.log(e);
                this.snackbar_error = true;
                this.snackbar_error_text = 'Error getting product data.';
            }

        },

        preSaveProdInit(){
            this.show_prod_init_save_name = true;
        },

        //TODO here
        async saveProdInit(){
            if(this.$refs.save_prod_init_name.validate()){
                this.loading_save = true;
                this.main_loading = true;

                const prod_init_save_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/prod-init/';
                try{
                    const response = await fetch(prod_init_save_url, {
                            method: 'POST',
                            headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                            },
                            body: JSON.stringify(
                                {
                                    "product": {"product_id": this.product_id},
                                    "artifact": {
                                        "artifact_name": this.prod_init_name,
                                        "artifact_data": this.document
                                    }
                                })
                    });

                    const content = await response.json();

                    if(response.status == 200){
                        this.prod_init_saved = true;
                        alert("Artifact Saved!");
                    }
                    //TODO: How to deal better with this
                    else if(response.status == 401 || response.status == 403){
                        this.$router.push('/login');
                    }
                    else if(response.status == 500){
                        console.log(content.message);
                        alert("Something wrong with the request.");
                        //push to 500 page
                    }
                    else{
                        alert("Something wrong with the request.");
                    }
                }
                catch(e){
                    alert("Something wrong with the request.");
                }
                finally{
                    this.loading_save = false;
                    this.main_loading = false;
                    this.show_prod_init_save_name = false;
                }
            }
        },

        async getQuestions(){
            if ( this.$refs.form.validate()) {
                const prod_init_qa_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/prod-init/generate-questions';
                this.loading_question_btn = true;
                this.main_loading = true;
                try{
                    const response = await fetch(prod_init_qa_url, {
                            method: 'POST',
                            headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                            },
                            body: JSON.stringify({
                                "product_name": this.product_name,
                                "product_description": this.product_description,
                                "new_feature": this.new_feature,
                                "feature_description": this.feature_description,
                                "n_quest": this.n_quest
                            })
                        });

                    const content = await response.json();

                    if(response.status == 200){
                        this.questions = content;
                        let min_quest = Math.min(this.n_quest, this.questions.length);
                        for (let i=0; i < min_quest; i++){
                            this.questions[i].answer = '';
                        }    
                        this.hasQuestions = true;
                        this.question_btn = false;
                    }
                    else if(response.status == 401){
                        this.$router.push('/login');
                    }
                    else if(response.status == 403){
                        this.$router.push('/');
                    }
                    else if(response.status == 500){
                        console.log(content);
                    }
                    else if(response.status == 429){
                        if(this.user_status === SUBSCRIBER_USER_ROLE_NAME){alert('You are making too many requests. Try again later.');}
                        else{alert('You have used all the free content. Please subscribe for more.'); this.$router.push('/pricing');}
                    }
                    else{
                        return
                    }

            }
            catch(e){
                console.log(e);
            }

            this.loading_question_btn = false;
            this.main_loading = false;
            }
            else {
                this.snackbar_error = true;
                this.snackbar_error_text = "All fields are required";
            }
        },

        async submitQuestions(){
            const prod_init_create_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/prod-init/generate';
            this.loading_submit_btn = true;
            this.main_loading = true;
            try{
                const response = await fetch(prod_init_create_url, {
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
                        },
                        body: JSON.stringify({
                            "product_name": this.product_name,
                            "product_description": this.product_description,
                            "new_feature": this.new_feature,
                            "feature_description": this.feature_description,
                            "qa_list": this.questions
                        })
                    });

                const content = await response.json();

                if(response.status == 200){
                    this.document = content;
                    this.hasProdInitDraft = true;
                    if(this.user_status === FREE_USER_ROLE_NAME){
                        this.generate_usage_left = await getGenerateFreeUsageLeft();
                        this.snackbar_usage = true;
                    }
                }
                //TODO: Better handling status codes
                else if(response.status == 401){
                    this.$router.push('/login');
                }
                else if(response.status == 403){
                    this.$router.push('/');
                }
                else if(response.status == 500){
                    console.log(content);
                    this.snackbar_error = true;
                    this.snackbar_error_text = content.message;
                }
                else if(response.status == 429){
                    if(this.user_status === SUBSCRIBER_USER_ROLE_NAME){alert('You are making too many requests. Try again later.');}
                    else{alert('You have used all the free content. Please subscribe for more.'); this.$router.push('/pricing');}
                }
            }
            catch(e){
                console.log(e);
            }

            this.loading_submit_btn = false;
            this.main_loading = false;
        },


        
    }
};

</script>

<style>

.flex-container {
  display: flex;
}

.col-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.row-container {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.custom-row{
  overflow-y: auto;
  overflow-x: hidden;
}

.copilot-card{
    border-radius: 16px !important;
}

.work-textarea{
    font-size: 14px;
}

.text-linebreak{
    white-space: pre-line;
}

.questions-card{
    border-radius: 16px !important;
}

.gradient-icon{
   background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

</style>