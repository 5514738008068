<template>
<v-dialog v-model="dialog_show" max-width = "520px">
    <v-sheet rounded class="dialog-sheet py-12 pl-2">
        <v-btn absolute top right icon small @click="closeDialog()" style="z-index: 10;">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-row justify="center" align="center" class="mb-6">
            <h2> Select the artifact type </h2>
        </v-row>
        <v-row justify="center" align="center" class="mb-n4">
            <v-col v-for="(item, key) in create_artifacts_list" :key="key" cols="6" class="mr-n10">
                <v-hover v-slot="{hover}">
                    <v-card
                    elevation="1" 
                    outlined max-width="200px" min-width="200px" min-height="150px" 
                    class="main-card mb-2" 
                    :class="{'hovered': hover, '': !hover}" 
                    @click="selectArtifact(item);"
                    >
                    <v-card-title class="small-font">
                        <v-icon size="16" left :class="{'gradient-icon': hover}">{{item.artifact_icon}}</v-icon>
                        <span :class="{'gradient-card-title': hover, 'inactive-card-title': !hover}">{{item.artifact_type}}</span>
                    </v-card-title>
                    <v-card-text class="mt-n4" :class="{'gradient-card-title': hover}">
                        {{item.artifact_description}} 
                    </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-sheet>
</v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
  data() {
    return {
        dialog_show: this.value,
        stepper: 1,
        create_artifacts_list: [
                {
                    "artifact_type": "PRD Writer",
                    "artifact_icon": "fi-sr-document",
                    "artifact_url": "/prd-writer/",
                    "artifact_description": "Generates a draft of a Product Requirements Documents."
                },
                {
                    "artifact_type": "Product Initiative",
                    "artifact_icon": "fi-sr-square-i",
                    "artifact_url": "/prod-init/",
                    "artifact_description": "Break big features into Epics with the Product Initiative tool."
                },
                {
                    "artifact_type": "Epic Writer",
                    "artifact_icon": "fi-sr-square-bolt",
                    "artifact_url": "/epic-writer/",
                    "artifact_description": "Generates Scrum Epics content for efficient product planning."
                },
                {
                    "artifact_type": "Import Diagram",
                    "artifact_icon": "fi-sr-diagram-project",
                    "artifact_url": "/import-diagram/",
                    "artifact_description": "Convert diagrams into user stories, epics and requirements."
                },
                {
                    "artifact_type": "Custom Text",
                    "artifact_icon": "fi-sr-poll-h",
                    "artifact_url": "/custom-text/",
                    "artifact_description": "Copy and Paste here any artifact related to your product."
                }
                

        ],
        selected_artifact: null,
        step_description: [],
        rules: {
            required: v => !!v || 'This field is required.'
        }
        };
  },
  methods: {
    closeDialog() {
        this.$emit('close-dialog');
    },
    selectArtifact(item) {
        //The item object should be emitted to the parent component
        this.$emit('selected-new-artifact', item);
        this.closeDialog();
    },
    submitArtifact() {
      
    }
  },
    watch: {
        value(newVal) {
            this.dialog_show = newVal;
        }
    }
};
</script>

<style scoped>
.small-font {
  font-size: 15px;  /* Adjust as per your need */
}

.dialog-sheet {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}
</style>