<template>
<v-layout align-center justify-center>
    <v-overlay :value="loading">
        <v-progress-circular
        indeterminate
        size="64"
        color="primary"
        ></v-progress-circular>
    </v-overlay>
    
  <div class="py-8 mb-4">
    <v-row class="justify-center">
            <v-col cols="12" class="d-flex align-center justify-center">
                <v-img
                    alt="Logo"
                    contain
                    src="../assets/pm_copilot_sq.png"
                    transition="scale-transition"
                    max-width="60"
                />
                
                <h1 class="ml-6">pmcopilot.ai</h1>
            </v-col>
        </v-row>

    <v-row class="mb-n2">
      <v-col cols="12" class="d-flex align-center justify-center">
        <v-form v-model="valid" ref="signUpForm" class="px-12">
          <v-text-field
            class="consistent-width"
            v-model="firstName"
            :rules="nameRules"
            label="First Name"
            required
          ></v-text-field>

          <v-text-field
            class="consistent-width"
            v-model="lastName"
            :rules="nameRules"
            label="Last Name"
            required
          ></v-text-field>

          <v-text-field
            class="consistent-width"
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-text-field
            class="consistent-width"
            v-model="confirmEmail"
            :rules="this.confirmEmailRules()"
            label="Confirm E-mail"
            required
          ></v-text-field>

          <v-text-field
            class="consistent-width"
            v-model="password"
            :rules="passwordRules"
            label="Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            required
          ></v-text-field>

          <v-text-field
            class="consistent-width"
            v-model="confirmPassword"
            :rules="this.confirmPasswordRules()"
            label="Confirm Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            required
          ></v-text-field>

          <v-checkbox
            v-model="acceptedTerms"
            :rules="termsRules"
          >
            <template v-slot:label>
              <span style="vertical-align: baseline; white-space: normal; max-width: 300px; font-size: 14px;">
                I accept the 
                <a href="https://app.pmcopilot.ai/terms-and-conditions" target="_blank" style="text-decoration: underline;" @click.stop>Terms and Conditions</a> 
                and 
                <a href="https://app.pmcopilot.ai/privacy-policy" target="_blank" style="text-decoration: underline;" @click.stop>Privacy Policy</a>.
              </span>
            </template>
          </v-checkbox>

        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="d-flex align-center justify-center">
        <v-form class="px-12">
          <v-btn class="btn-login" :disabled="!valid" color="#27374D" @click.prevent="submitForm()" outlined>
            Sign Up
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</v-layout>
</template>

<script>
import {firebase_auth_obj, firebase_auth} from '../plugins/firebase.js';

export default {
    data: () => ({
        loading: false,
        valid: true,
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        showPassword: false,
        acceptedTerms: false,
        nameRules: [v => !!v || "Name is required"],
        emailRules: [
            v => !!v || "E-mail is required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        passwordRules: [
            v => !!v || "Password is required",
            v => v.length >= 12 || "Password must be at least 12 characters"
        ],
        termsRules: [
          v => !!v || 'You must accept the terms and conditions'
        ]
    }),

    methods: {
      handleSignUp(){
        try{
          this.gtagReportConversion('/'); 
          return
        }
        catch(e){
          console.log("Error at the tracking.");
        }

        this.$router.push('/');
        
      },

      gtagReportConversion(route) {
        let callback = () => {
          if (typeof route !== 'undefined') {
            this.$router.push(route);
          }
        };

        window.gtag('event', 'conversion', {
          send_to: 'AW-11368247082/Jv74CL6KxuoYEKre5qwq',
          event_callback: callback
        });
      },

      async submitForm() {
          if (this.$refs.signUpForm.validate()) {
              this.loading = true;
              try{
                  const auth_result = await firebase_auth_obj.createUserWithEmailAndPassword(firebase_auth, this.email, this.password);
                  const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/users/new';
                  const response = await fetch(base_url, {
                      method: 'POST',
                      headers: {
                      'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                          user_id: String(auth_result.user.uid),
                          email: this.email,
                          method: 'E-mail',
                          user_data: {name: this.firstName + ' ' + this.lastName}
                      })
                  });

                  // If status success (code 200)
                  if(response.status != 200){
                      throw Error('Error creating user.');
                  }
                  else{
                    this.handleSignUp();
                  }
              }
              catch(error){
                  alert(error.message);
                  this.loading = false;
                  return;
              }

              this.$router.push('/');
          }
      },
      confirmEmailRules() {
          return [
          v => !!v || "Email confirmation is required",
          v => v === this.email || "Emails must match"
          ];
      },
      confirmPasswordRules(){
          return [
              v => !!v || "Confirmation Password is required",
              v => v === this.password || "Passwords must match"
          ];
      }
    }
};
</script>
<style scoped>

.btn-login {
    min-width: 340px !important;
}

.consistent-width {
    min-width: 300px !important;
}

</style>