<template>
<div class="app-container">
  <v-app :style="{background:'#F1F1F1'}">
    <router-view/>
  </v-app>
</div>
</template>

<script>

export default {
  name: 'App',

  components: {

  },

  data: () => ({
    onboarding: false,
    prd_writer: false,
    drawer: true,
  }),

  mounted() {

  },

  methods: {

  }
};
</script>
