import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';

//Artifact-Related
import PRDWriterView from '../view/PRDWriterView.vue';
import EpicWriterView from '../view/EpicWriterView.vue';
import ProdInitWriterView from '../view/ProdInitWriterView.vue';
import ProductView from '../view/ProductView.vue';
import CustomTextView from '../view/CustomTextView.vue';
import DiagramView from '../view/DiagramView.vue';
import DashboardView from '../view/DashboardView.vue';

//User-Related
import LoginView from '../view/LoginView.vue';
import SignUpView from '../view/SignUpView.vue';
import PriceTableView from '../view/PriceTableView.vue';
import SettingsView from '../view/SettingsView.vue';

//Product-Related
//import ProductView from '../view/ProductView.vue';
import ProductSettingsView from '../view/ProductSettingsView.vue';
import ProductArtifactView from '../view/ProductArtifactView.vue';

//Operational-Related
import OperationalView from '../view/operational/OperationalView.vue';
import TaskPriorityView from '../view/operational/TaskPriorityView.vue';

// Override the 'push' method
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  return originalPush.call(this, location, onResolve, onReject).catch(err => {
    if (err.name === 'NavigationDuplicated') {
      // Ignore NavigationDuplicated error
      return;
    }
    // Re-throw other errors
    throw err;
  });
};


const routes = [
    //Generic Routes
    {path: '/', component: DashboardView, meta: {title: "PMCopilot.ai", requiresAuth: true}},
    {path: '/login', component: LoginView, meta: {title: "Enter - PMCopilot.ai", requiresAuth: false}},
    {path: '/signup', component: SignUpView, meta: {title: "Sign Up - PMCopilot.ai", requiresAuth: false}},
    {path: '/pricing', component: PriceTableView, meta: {title: "Pricing - PMCopilot.ai", requiresAuth: true}},
    {path: '/settings', component: SettingsView, meta: {title: "Settings - PMCopilot.ai", requiresAuth: true}},

    //NavBar Routes
    {path: '/products', component: ProductView, meta: {title: "PMCopilot.ai", requiresAuth: true}},
    {path: '/settings/product/:id', component: ProductSettingsView, props: true, meta: {title: "Product Settings - PMCopilot.ai", requiresAuth: true}},
    {path: '/artifacts', component: ProductArtifactView, props: true, meta: {title: "Artifacts - PMCopilot.ai", requiresAuth: true}},
    {path: '/operational', component: OperationalView, props: true, meta: {title: "Operational - PMCopilot.ai", requiresAuth: true}},
    

    //Artifacts Routes
    {path: '/prd-writer/:product_id', component: PRDWriterView, props: true, meta: {title: "Product Requirements Document - Writer - PMCopilot.ai" , requiresAuth: true}},
    {path: '/epic-writer/:product_id', component: EpicWriterView, props: true, meta: {title: "Agile Epic - Writer - PMCopilot.ai", requiresAuth: true}},
    {path: '/prod-init/:product_id', component: ProdInitWriterView, props: true, meta: {title: "Product Initiative - Writer - PMCopilot.ai", requiresAuth: true}},
    {path: '/import-diagram/:product_id', component: DiagramView, props: true, meta: {title: "Import Diagram - Writer - PMCopilot.ai", requiresAuth: true}},  
    {path: '/custom-text/:product_id', component: CustomTextView, props: true, meta: {title: "Custom Text - PMCopilot.ai" , requiresAuth: true}},
    
    //Operational Routes
    {path: '/task-priority/:product_id', component: TaskPriorityView, props: true, meta: {title: "Task Priority - PMCopilot.ai", requiresAuth: true}},
]

const router = new VueRouter({mode: 'history', routes, base: process.env.VUE_APP_BASE_PATH});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
})

async function checkUserLoggedin(){
    const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/users';
        try{
            const response = await fetch(base_url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + VueCookies.get(process.env.VUE_APP_TOKEN_KEY)
                },
            });

            // If status success (code 200)
            if(response.status == 200){
                return {success: true};
            }
            if(response.status == 401){
                return {success: false, message: 'Failed to authenticate'};
            }
            if(response.status == 404){
                return {success: false, message: 'Server is down'};
            }
            if(response.status == 500){
                return {success: false, message: 'Internal Server Error'};
            }

            return {success: false, message: 'Unknown Error'};
        }
        catch(e){
            return {success: false, message: e.message};
        }
}

router.beforeEach(async (to, from, next) => {

    //Authenticated user block
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // This route requires authentication. Check if user has permission.
        const result = await checkUserLoggedin();
        if (!result.success) {
            if(result.message === 'Failed to authenticate'){
                next({ path: '/login' });
            }
            else{
                alert(result.message);
                return;
            }
        }
        else{
            next();
        } 
    } else {
        next();
    }

    
})

export default router