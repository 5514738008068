import { getUserAuthToken } from "@/utils/cookies";

const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/artifacts/epic';

export async function getQuestionsContext(product, feature, n_quest, context_artifacts_id){

    const url = base_url + '/context/generate-questions';

    const response = await fetch(url, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getUserAuthToken()
        },
        body: JSON.stringify({
            "product_name": product.product_name,
            "product_description": product.product_description,
            "new_feature": feature.new_feature,
            "feature_description": feature.feature_description,
            "n_quest": n_quest,
            "context_ids": context_artifacts_id
        })
    });

    return response;

}

export async function generateEpicContext(product, feature, n_quest, questions, context_artifacts_id){

    const url = base_url + '/context/generate';

    const response = await fetch(url, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getUserAuthToken()
        },
        body: JSON.stringify({
            "product_name": product.product_name,
            "product_description": product.product_description,
            "new_feature": feature.new_feature,
            "feature_description": feature.feature_description,
            "n_quest": n_quest,
            "qa_list": questions,
            "context_ids": context_artifacts_id
        })
    });

    return response;

}
