import Vue from 'vue';
import Vuetify from 'vuetify';
import '@flaticon/flaticon-uicons/css/all/all.css'

Vue.use(Vuetify);

export default new Vuetify({
theme: {
    themes: {
      light: {
        primary: '#496791',
        secondary: '#30b6f9',
        dominant: '#526D82',
        error: '#b71c1c',
        navbar: '#FAFAFA',
        navbartext: '#8BBCCC',
        sidebartext: '#FFFFFF'
      },
    },
  },
icons: {
  iconfont: 'mdi'
}
});
