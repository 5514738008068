<template>
<div>
  <SidebarComp></SidebarComp>
  <v-main>
    <v-overlay :value="main_loading">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
    </v-overlay>
    <!-- Delete prompt dialog -->
    <div id="delete-dialog">
        <v-dialog v-model="delete_dialog" persistent max-width="300px">
            <v-card class="pb-2">
                <v-card-title>Confirmation</v-card-title>
                <v-card-text>Are you sure you want to delete this product?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="deleteProduct()">Yes</v-btn>
                    <v-btn color="primary" text @click="delete_dialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <v-container fluid class="breathing-room main-container">   
          <v-row class = "pt-8">
            <v-col cols="12 d-flex">
              <h1 class="ml-4"> My Products </h1>
            </v-col>
          </v-row>
          <v-row class="mb-n2">
            <v-col cols ="12 d-flex">
              <v-dialog v-model="dialog" persistent max-width="600px">
                <CreateProduct @create-product="addProduct" @close-dialog="dialog = false"> </CreateProduct>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                  color="black"
                  class="ml-4"
                  outlined
                  v-bind="attrs" v-on="on"
                  >
                    <v-icon left>
                      mdi-plus-circle
                    </v-icon>
                    New Product
                  </v-chip>
                </template>
              </v-dialog>
              <v-chip
                v-if="products.length == 0"
                color="green"
                class="ml-4"
                outlined
                @click="createTestProduct()"
              >
                <v-icon left>
                  mdi-plus-circle
                </v-icon>
                Test Product
              </v-chip>
            </v-col>
          </v-row>
            <v-row>
              <v-col cols = "12">
                <v-col cols="12">
                    <v-data-table 
                        hide-default-header
                        :headers="headers"
                        :items="products">
                      <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.product_name }}</td>
                        <td>{{ item.updated }}</td>

                        <!-- Action 2 Column -->
                        <td class="action-column">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click="selectProduct(item)">
                                <v-icon>mdi-sprout</v-icon> <!-- Example icon for action 2 -->
                              </v-btn>
                            </template>
                            <span>Select Product</span> <!-- Tooltip text for Action 1 -->
                          </v-tooltip>
                        </td>

                        <!-- Action 1 Column -->
                        <td class="action-column">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click="editProduct(item)">
                                <v-icon>mdi-pencil</v-icon> <!-- Example icon for action 1 -->
                              </v-btn>
                            </template>
                            <span>Edit Product</span> <!-- Tooltip text for Action 1 -->
                          </v-tooltip>
                        </td>

                        <!-- Action 3 Column -->
                        <td class="action-column">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click="deleteProductDialog(item)">
                                <v-icon>mdi-delete</v-icon> <!-- Example icon for action 2 -->
                              </v-btn>
                            </template>
                            <span>Delete Product</span> <!-- Tooltip text for Action 1 -->
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                    </v-data-table>
                </v-col>
              </v-col>
            </v-row>
    </v-container>
  </v-main>
</div>
</template>

<script>
import SidebarComp from '@/components/SidebarComp.vue';
import CreateProduct from '@/components/CreateProductStepper.vue'
import {createProduct, deleteProduct} from '@/services/products';

export default {
  name: 'ProductView',

  components: {
    SidebarComp,
    CreateProduct
  },

  data: () => ({
    activeCard: false,
    main_loading: false,
    dialog: false,
    delete_dialog: false,
    to_be_deleted_product: {},
    products: [],
    headers: [
      { text: 'Name', value: 'product_name' },
      { text: 'Last Updated', value: 'updated' },
      { text: 'Select Product', value: 'select', sortable: false, align: 'end' },
      { text: 'Edit Product', value: 'edit', sortable: false, align: 'end' },
    ]
  }),

  created(){
    this.getProducts();
  },

  methods:{
    //TODO: Use products module (still need to develop)
    async getProducts(){
      this.main_loading = true;
      try{
        const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products?order=desc';
        const response = await fetch(base_url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
            }
        });

        // If status success (code 200)
        if(response.status != 200){
            throw Error('Error getting Products.');
        }
        else{
          let products = await response.json();
          this.products = products.map(item => {
            item.updated = this.humanDate(item.updated);
            return item;
          });
          let active_product = this.getActive(this.products);
          this.changeProduct(active_product);
        }

      }
      catch(error){
        alert(error.message);
      }
      finally{
        this.main_loading = false;
      }
    },
    getActive(arr) {
      for (let obj of arr) {
        if (obj.active) {
          return obj;
        }
      }
      return null; // or return -1, or any other value to indicate no active element was found
    },
    deleteProductDialog(product){
      this.to_be_deleted_product = product;
      this.delete_dialog = true;
    },
    async deleteProduct(){
      try{
          let result = await deleteProduct(this.to_be_deleted_product.product_id);
          if(result){
            for (let i = 0; i < this.products.length; i++) {
              if (this.products[i].product_id === this.to_be_deleted_product.product_id) {
                  this.products.splice(i, 1);
                  break; // Exit the loop if the product is found and removed
              }
          }
              this.delete_dialog = false;
          }
      }
      catch(e){
          console.log(e);
          this.snackbar_error = true;
          this.snackbar_error_text = 'Error deleting the product';
      }
      finally{
        this.to_be_deleted_product = {};
      }

    },
    async selectProduct(product){
      try{
        const base_url = process.env.VUE_APP_BACKEND_ADDRESS + '/api/v1/products/' + product.product_id + '/active';
        const response = await fetch(base_url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$cookies.get(process.env.VUE_APP_TOKEN_KEY)
            }
        });

        // If status success (code 200)
        if(response.status != 200){
            throw Error('Error activating the product.');
        }
        else{
          this.changeProduct(product);
          alert(product.product_name + " selected.");
          this.$router.push('/artifacts');
        }

      }
      catch(error){
        alert(error.message);
      }
      finally{
        this.main_loading = false;
      }
    },
    editProduct(product){
      this.$router.push('/settings/product/' + product.product_id);
    },
    changeProduct(product){
      this.$store.dispatch('setActiveProduct', product);
    },
    async createTestProduct(){
      const product_name = "Procrastinator's Paradise App";
      const product_description = "Dive into the digital haven for every delay-lover with \
                                  the Procrastinator's Paradise App! Create Tomorrow's To-Do lists \
                                  (because why do today what you can put off till tomorrow?), enjoy \
                                  reassuring quotes about taking things slow, and set nap alarms that \
                                  cheer for your restfulness. Feeling pseudo-productive? Watch others \
                                  work with our Work Simulations. Connect with fellow delay-devotees in \
                                  chat rooms. Get it today, or... whenever. Because there's always 'later' \
                                  in paradise!";
      let product_id;

      try{
        product_id = await createProduct(product_name,product_description);
        let prod = {
          product_id: product_id, 
          product_name: product_name, 
          product_description: product_description,
          updated: new Date()
        }
        this.addProduct(prod);
      }
      catch(e){
        alert("Something wrong creating the product");
        return
      }
      
      

    },
    humanDate(robot_date){
      let date = new Date(robot_date);
      let humanReadableDate = date.toLocaleString();
      return humanReadableDate;
    },
    addProduct(prod){
      prod.updated = this.humanDate(prod.updated);
      this.products.unshift(prod);
    },

    comingSoon(){
      alert("Feature Coming Soon!");
    }
  }
};

</script>

<style scoped>
.product-card {
    border-radius: 16px !important;
    cursor: pointer !important;
    /*height: 100px !important;*/
    width: 300px !important;
}

.gradient-icon{
   background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.inactive-card-title{
    color: gray;
}

.active-text{
    color: black !important;
}

.gradient-card-title{
   background: linear-gradient(to right, #27374D  0%, #007232 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.hovered{
    border: 2px solid #007232 !important;
}

.card-title {
  white-space: nowrap;      /* Prevents the text from wrapping onto next line */
  overflow: hidden;         /* Hides any text that overflows the containing element */
  text-overflow: ellipsis !important;  /* Shows an ellipsis (...) when text overflows */
}

.h3-title{
  color: #62758f !important;
}

/* Default (XS) */
.breathing-room {
padding-left: 5% !important;
padding-right: 5% !important;
}

/* SM and up */
@media (min-width: 600px) {
.breathing-room {
    padding-left: 7% !important;
    padding-right: 7% !important;
}
}

/* MD and up */
@media (min-width: 960px) {
.breathing-room {
    padding-left: 10% !important;
    padding-right: 10% !important;
}
}

/* LG and up */
@media (min-width: 1264px) {
.breathing-room {
    padding-left: 20% !important;
    padding-right: 20% !important;
}
}

/* XL and up */
@media (min-width: 1904px) {
.breathing-room {
    padding-left: 25% !important;
    padding-right: 25% !important;
}
}

.main-container{
  overflow-y: auto !important;
  height: 100vh;
}

.action-column {
  width: 20px; /* Adjust this value as needed */
  text-align: right;
}

</style>