<template>
    <div>
        <SidebarComp></SidebarComp>
        <div id="snackbar-container">
            <!-- ERROR AND INFORMATION SNACKBARS -->
            <v-snackbar
                v-model="snackbar_error"
                :color="'error'"
                :timeout="5000"
                :top="true"
                >
                {{ snackbar_error_text }}
            </v-snackbar>
            <!--------------------------------->
        </div>

        <v-main>
            <v-overlay :value="main_loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
            <div id="dialogs-container">
                <v-dialog id="save-operational-dialog" v-model="save_dialog" max-width="400px">
                    <v-card class="pa-6">
                        <v-form ref="save_operational_name" @submit.prevent="saveOperational()">
                            <v-text-field class="work-textarea" outlined dense counter maxlength="50" v-model="operational_name" label="Job Custom Name" :rules="[rules.required]"> </v-text-field> 
                            <v-btn color="primary" @click="saveOperational()" :loading="loading_save" :disabled="operational_saved"> Save </v-btn>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-dialog id="user-leaving-dialog" v-model="user_leaving_dialog" persistent max-width="400px">
                    <v-card>
                        <v-card-text class="pt-6">You haven't saved your work. Are you sure you want to leave?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" outlined text @click="confirmLeave()">Yes, discard this.</v-btn>
                            <v-btn text @click="user_leaving_dialog = false">No, let me save it.</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
                <v-container fluid class="pt-12 pl-12 pr-12 v-scroll-y main-container breathing-room">      
                    <div id="operational-section">
                        <div id="operational-header">
                            <v-row class = "mt-8">
                                <v-col cols="12 d-flex">
                                    <v-icon left> mdi-tray-full </v-icon> <h2> Task Priority </h2>
                                    
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12 d-flex">
                                    <p class="mt-n4"> Get help with prioritizing tasks, features and any other issues. </p>
                                </v-col>
                            </v-row>
                        </div>

                        <div id="priority-details-container" v-if="!hasQuestions">
                            <v-row class="mb-n6">
                                <v-col cols="12 d-flex" >
                                    <v-text-field
                                    class="custom-input"
                                    label="What's behind this decision?"
                                    v-model="decision_context"
                                    maxlength="500"
                                    counter
                                    filled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mb-n6">
                                <v-col cols="8 d-flex">
                                    <v-select
                                        v-model="selectedDecisionStyle"
                                        :items="decisionStyles"
                                        item-text="name"
                                        item-value="name"
                                        label="What's your decision-making style?"
                                        filled
                                    >
                                        <template v-slot:item="{ item }">
                                            <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-on="on" v-bind="attrs">{{ item.name }}</div>
                                            </template>
                                            <span>{{ item.description }}</span>
                                            </v-tooltip>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-center align-center mb-6">
                                    <v-btn
                                        color="black"
                                        outlined
                                        @click="addNewTask"
                                        width="150px"
                                        >
                                            <v-icon left>
                                            mdi-plus-circle
                                            </v-icon>
                                            New Task
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12 d-flex">
                                    <div class="full-width">
                                        <v-text-field
                                            v-for="(task) in tasks"
                                            class="full-width"
                                            v-model="task.task"
                                            outlined
                                            :placeholder="'Task ' + String(task.id) "
                                            :key="task.id"
                                            append-icon="mdi-close-circle"
                                            @click:append="removeTask(task.id)"
                                        >
                                        </v-text-field>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-if="tasks.length < 1" class="mt-n4 mb-2">
                                <v-col cols="12" class="d-flex justify-center">
                                    <p> No tasks added </p>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="mt-6">
                                <v-col cols ="8 d-flex">

                                </v-col>
                                <v-col cols ="4" class="d-flex justify-center">
                                    <v-btn
                                        :disabled="okForQuestions"
                                        :loading="loading_nextForQuestions"
                                        color="primary"
                                        width="150px"
                                        @click="nextForQuestions()"
                                        >
                                            Next
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <div id="questions-section" v-if="hasQuestions && !hasDecision">
                            <v-form ref="questions_answers_form">
                                <div id="question-container" class="full-width" v-for="q in questions" :key="q.id">
                                    <v-row class="ml-n8 mt-n6">
                                        <v-col cols="12 d-flex">
                                            <v-list-item class="full-width">
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-wrap full-width mb-2">{{q.question}}</v-list-item-title>
                                                    <v-text-field class="full-width" 
                                                    outlined dense counter maxlength="300" v-model="q.answer" :rules="[rules.required]"> 
                                                    </v-text-field>    
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row class="mt-2">
                                    <v-col cols ="8 d-flex">

                                    </v-col>
                                    <v-col cols ="4" class="d-flex justify-center">
                                        <v-btn
                                            :loading="loading_nextForDecision"
                                            color="primary"
                                            width="150px"
                                            @click="nextForDecision()"
                                            >
                                                Get Decision
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>

                        <div id="decisions-section" v-if="hasDecision">
                            <v-row>
                                <v-col cols="12 d-flex">
                                    <div class="full-width">
                                        <v-card id="decision-card" class="pa-2 text-linebreak rounded-xl full-width">
                                                <v-card-title> Decision 
                                                        <v-spacer></v-spacer> 
                                                        <v-icon class="mr-2" color="gray" @click="preSaveOperational()" 
                                                                > 
                                                                mdi-content-save 
                                                        </v-icon> 
                                                        <v-icon color="gray" :style="{'transform': 'rotate(90deg)'}" 
                                                                @click="copyContent('decision-card')"
                                                                > 
                                                                mdi-card-multiple 
                                                        </v-icon> 
                                                </v-card-title>
                                                <v-card-text> {{decisions.decision + '\n'}} </v-card-text>
                                                <v-card-subtitle class="text--primary mb-n4"> {{'Reasoning' + '\n'}} </v-card-subtitle>
                                                <div v-for="(r, i) in decisions.reasons" :key="i">
                                                    <v-card-text class="text--secondary">{{r.reason + '\n'}}</v-card-text>
                                                </div>
                                        </v-card>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        
                    </div>
                </v-container>
        </v-main>
    </div>
</template>

<script>
import SidebarComp from '@/components/SidebarComp.vue';

import {getProduct} from "@/services/products.js";
import {getOperational, getTaskPriorityQuestions, getTaskPriorityDecisions, saveTaskPriority} from "@/services/operational.js";

import {copyCardContent} from "@/utils/utils.js";


export default {
    name: 'TaskPriorityView',

    components: {
        SidebarComp,
    },

    //props: ['id'],

    data: () => ({

        product: {
            product_name: ''
        },

        id: '',

        main_loading: true,

        operationals: [ ],
        decision_context: "",
        decision_style: '',
        newTask: '',
        tasks: [],
        selectedDecisionStyle: '',
        decisionStyles: [
            { name: 'Impact vs. Feasibility', description: 'Assessing options based on their potential impact and ease of implementation.' },
            { name: 'Trend Analysis', description: 'Making decisions based on observed trends and patterns in relevant data or past experiences.' },
            { name: 'Risk Assessment', description: 'Evaluating options by identifying and analyzing potential risks and their consequences.' },
            { name: 'Consensus Building', description: 'Aiming for a decision that seeks agreement among all team members or stakeholders.' },
            { name: 'Sequential Elimination', description: 'Systematically ruling out options until one viable solution remains.' },
        ],

        snackbar_error: false,
        snackbar_error_text: '',

        hasQuestions: false,
        loading_nextForQuestions: false,

        hasDecision: false,
        loading_nextForDecision: false,

        rules: {
            required: value => !!value || 'Required',
        },

        questions: [],
        decisions: [],

        operational_saved: false,
        save_dialog: false,
        loading_save: false,
        operational_name: '',
        user_leaving_dialog: false,
        can_leave: false,
        next_route: '',

    }),

    beforeRouteLeave(to, from, next) {
        if (this.operational_saved || this.can_leave || (!this.operational_saved && !this.hasDecision)) {
            next();
        } else {
            this.user_leaving_dialog = true;
            this.nextRoute = to.path; 
        }
    },

    async mounted(){
        let product = await this.getActiveProduct();
        if(product){
            this.id = product.product_id;
            this.getProduct();
            this.getOperational();
        }
        else{
            alert('Select or Create a Product first.');
            this.$router.push('/products');
        }
        this.main_loading = false;
        
        
    },

    computed: {
        okForQuestions(){
            if (this.tasks.length < 2) {
                return true;
            }

            let assert_empty_fields = this.selectedDecisionStyle === undefined || this.selectedDecisionStyle === null || this.selectedDecisionStyle === '' ||
                this.decision_context === undefined || this.decision_context === null || this.decision_context === '';

            let assert_empty_tasks = !this.tasks.every(item => item.task !== '');

            // Check if every element has a non-empty 'description' field
            return assert_empty_fields || assert_empty_tasks;
        },
        okForDecision(){
            return !this.questions.every(item => item.answer !== '');
        }
    },
    

    methods: {
        //UTILS
        humanDate(robot_date){
            let date = new Date(robot_date);
            let humanReadableDate = date.toLocaleString();
            return humanReadableDate;
        },
        copyContent(element_id){
            copyCardContent(element_id);
        },
        confirmLeave() {
            this.can_leave = true;
            this.user_leaving_dialog = false;
            this.$router.push(this.nextRoute); // navigate to the next route
        },
        getLastId(list) {
            // Check if the list is not empty
            if (list && list.length > 0) {
                // Return the id of the last element
                return list[list.length - 1].id;
            } else {
                // Return null or some default value if the list is empty
                return 0;
            }
        },

        //PRODUCT Functions
        async getActiveProduct() {
            // If it's not set, fetch it
            if (!this.$store.getters.activeProduct) {
                await this.$store.dispatch('fetchActiveProduct');
            }

            return this.$store.getters.activeProduct;
        },
        async getProduct(){
            let product;
            try{
                product = await getProduct(this.id);
                this.product = product;
            }
            catch(e){
                console.log(e);
                this.snackbar_error = true;
                this.snackbar_error_text = 'Error getting product data.';
            }
        },

        //Operational Functions
        async submitQuestions(){
            this.decision_style = this.selectedDecisionStyle.name;
        
            return await getTaskPriorityQuestions(this.product.product_description, this.decision_context, this.decision_style, this.tasks);
        },

        async submitDecisions(){
            return await getTaskPriorityDecisions(this.product.product_description, this.decision_context, this.decision_style, this.tasks, this.questions);
        },

        async nextForQuestions(){
            this.loading_nextForQuestions = true;
            try{
                this.questions = await this.submitQuestions();
                for (let i=0; i < this.questions.length; i++){
                    this.questions[i].id = i;
                    this.questions[i].answer = '';
                }    
                this.hasQuestions = true;
            }
            catch(e){
                console.log(e);
            }

            this.loading_nextForQuestions = false;
        },

        async nextForDecision(){
            if(this.$refs.questions_answers_form.validate()){
                this.loading_nextForDecision = true;
                try{
                    this.decisions = await this.submitDecisions();
                    this.hasDecision = true;
                }
                catch(e){
                    console.log(e);
                }

                this.loading_nextForDecision = false;
            }
        },
        
        async getOperational(){

            let operational;

            try{
                operational = await getOperational({"product": this.id, "order": "desc"});
                this.operationals = operational.map(item => {
                        item.updated = this.humanDate(item.updated);
                        return item;
                });
            }
            catch(e){
                alert('Error getting Operational.');
            }

        },
        closeNewOperational(){
            this.new_operational_dialog = false;   
        },
        handleSelectedOperational(operational_obj){
            this.$router.push(operational_obj.operational_url + this.id);
            return;
        },
        showOperational(item){
            console.log("Show", item);
        },
        addNewTask() {
            let last_id = this.getLastId(this.tasks);
            this.tasks.push({id: last_id + 1, description: ''});
        },
        removeTask(id) {
            this.tasks = this.tasks.filter(item => item.id !== id)
        },
        preSaveOperational(){
            this.save_dialog = true;
        },
        async saveOperational(){
            if(this.$refs.save_operational_name.validate()){
                this.loading_save = true;
                let operational_content = {
                    "prod_desc": this.product.product_description,
                    "decision_context": this.decision_context,
                    "decision_style": this.decision_style,
                    "tasks": this.tasks,
                    "qa_list": this.questions,
                    "decisions": this.decisions
                };
                try{
                    await saveTaskPriority(this.id, this.operational_name, operational_content);
                    this.operational_saved = true;
                    alert("Saved operational job.");
                }
                catch(e){
                    console.log(e);
                    alert("Could not save operational.");
                }
                finally{
                    this.loading_save = false;
                    this.save_dialog = false;
                }
            }
        }

        

    },

    
}
</script>

<style scoped>
    .main-card {
        border-radius: 16px !important;
        cursor: pointer !important;
    }

    .gradient-icon{
    background: linear-gradient(to right, #27374D  0%, #00D75D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    }

    .inactive-card-title{
        color: gray;
    }

    .active-text{
        color: black !important;
    }

    .full-width {
        width: 100% !important; /* Makes the text field take full width */
    }

    .main-container{
        overflow-y: auto !important;
        height: 100vh;
    }

    .task-container{
        border: 1px solid gray;
        height: 50px;
        width: 100%;
    }

    .link-style {
        text-decoration: underline; /* Add an underline */
        cursor: pointer; /* Change the cursor to a pointer */
    }

    /* Default (XS) */
    .breathing-room {
    padding-left: 5% !important;
    padding-right: 5% !important;
    }

    /* SM and up */
    @media (min-width: 600px) {
    .breathing-room {
        padding-left: 7% !important;
        padding-right: 7% !important;
    }
    }

    /* MD and up */
    @media (min-width: 960px) {
    .breathing-room {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
    }

    /* LG and up */
    @media (min-width: 1264px) {
    .breathing-room {
        padding-left: 20% !important;
        padding-right: 20% !important;
    }
    }

    /* XL and up */
    @media (min-width: 1904px) {
    .breathing-room {
        padding-left: 25% !important;
        padding-right: 25% !important;
    }
    }



</style>